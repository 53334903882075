import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHourglassHalf } from "@fortawesome/free-regular-svg-icons";
import {
  Icon,
  UilChartPie,
  UilShoppingBag,
  UilFileCheckAlt,
  UilMoneyStack,
} from "@iconscout/react-unicons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import {
  faShieldAlt,
  faPalette,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";

export interface Route {
  name: string;
  icon?: IconProp | string | string[] | any;
  iconSet?: "font-awesome" | "feather" | "unicons";
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  path?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: "dashboard", // Change label if needed
    horizontalNavLabel: "home",
    active: true,
    path: "/",
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: "theme", // Theme route
        icon: faPalette,
        iconSet: "font-awesome",
        path: "/apps/e-commerce/admin/setting/theme", // Updated path for theme
        pathName: "theme",
        active: true,
      },
      {
        name: "security", // Security route
        icon: faShieldAlt,
        iconSet: "font-awesome",
        path: "/apps/e-commerce/admin/setting/security", // Updated path for security
        pathName: "security",
        active: true,
      },
      {
        name: "privacy", // Privacy route
        icon: faUserShield,
        iconSet: "font-awesome",
        path: "/apps/e-commerce/admin/setting/privacy", // Updated path for privacy
        pathName: "privacy",
        active: true,
      },
    ],
  },
];
