import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en.json";
import translationFR from "./locales/fr.json";
import translationAR from "./locales/ar.json";

const resources = {
  en: { translation: translationEN },
  fr: { translation: translationFR },
  ar: { translation: translationAR },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    debug: true, // Debug mode
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

// Listen for language changes and apply the text alignment dynamically
i18n.on("languageChanged", (lng) => {
  if (lng === "ar") {
    document.body.classList.add("text-align-right"); // Add class for RTL text alignment
  } else {
    document.body.classList.remove("text-align-right"); // Remove class when not Arabic
  }
});

export default i18n;
