import React, { useEffect } from "react";
import { useAppContext } from "providers/AppProvider";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon, faDesktop } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface ThemeTogglerProps {
  className?: string;
}

const ThemeToggler: React.FC<ThemeTogglerProps> = ({ className }) => {
  const {
    config: { theme },
    setConfig,
  } = useAppContext();

  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.remove("light", "dark", "auto");
    document.body.classList.add(theme);
  }, [theme]);

  return (
    <div className={`theme-toggler ${theme} ${className}`}>
      <Form.Group>
        <Form.Check
          type="radio"
          label={
            <span>
              <FontAwesomeIcon icon={faSun} className="theme-icon me-2" />{" "}
              {t("themeLight")}
            </span>
          }
          name="themeToggleRadio"
          checked={theme === "light"}
          onChange={() => setConfig({ theme: "light" })}
          className="form-check"
        />
        <Form.Check
          type="radio"
          label={
            <span>
              <FontAwesomeIcon icon={faMoon} className="theme-icon me-2" />{" "}
              {t("themeDark")}
            </span>
          }
          name="themeToggleRadio"
          checked={theme === "dark"}
          onChange={() => setConfig({ theme: "dark" })}
          className="form-check"
        />
        <Form.Check
          type="radio"
          label={
            <span>
              <FontAwesomeIcon icon={faDesktop} className="theme-icon me-2" />{" "}
              {t("themeSystem")}
            </span>
          }
          name="themeToggleRadio"
          checked={theme === "auto"}
          onChange={() => setConfig({ theme: "auto" })}
          className="form-check"
        />
      </Form.Group>
    </div>
  );
};

export default ThemeToggler;
