import React, { useState, useEffect, useTransition } from "react";
import {
  Table,
  Col,
  Button,
  Row,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import VariantImage from "./VariantImage";
import { FileAttachment } from "./types";
import { useTranslation } from "react-i18next";

interface ProductVariantsTableProps {
  variantsData: any[];
  selectedAttributes: {
    attribute_code: string;
    frontend_label: string;
    is_imaged?: boolean;
  }[];
  onDeleteVariant: (compositeKey: string) => void;
  onUpdateVariant: (updatedVariants: any[]) => void;
  formValues: { [key: string]: any };
  onUpdateVariantImage: (
    imagedAttributeCode: string,
    imagedAttributeValue: string,
    newImage: FileAttachment
  ) => void;
}

const TablePagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}) => {
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <Pagination className="justify-content-end mt-3">
      <Pagination.First onClick={() => handlePageChange(1)} />
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      <Pagination.Item active>{currentPage}</Pagination.Item>
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last onClick={() => handlePageChange(totalPages)} />
    </Pagination>
  );
};

const ProductVariantsTable: React.FC<ProductVariantsTableProps> = ({
  variantsData: initialVariantsData,
  selectedAttributes,
  onDeleteVariant,
  onUpdateVariant,
  formValues,
  onUpdateVariantImage,
}) => {
  const [variantsData, setVariantsData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const [masterPrice, setMasterPrice] = useState("");
  const [masterQuantity, setMasterQuantity] = useState("");

  const imagedAttributeCode = selectedAttributes.find(
    (attr) => attr.is_imaged
  )?.attribute_code;

  const isExpedieParWamia =
    formValues.mode_expedition?.[0]?.option_id === "16500";
  useEffect(() => {
    // Remove any code related to setting image or id here.
    if (imagedAttributeCode) {
      const sortedData = [...initialVariantsData].sort((a, b) => {
        const aValue = a[imagedAttributeCode]?.value || "";
        const bValue = b[imagedAttributeCode]?.value || "";
        return aValue.localeCompare(bValue);
      });
      setVariantsData(sortedData);
    } else {
      setVariantsData([...initialVariantsData]);
    }
  }, [initialVariantsData, imagedAttributeCode]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, variantsData.length);

  const paginatedVariants = variantsData.slice(startIndex, endIndex);

  const totalPages = Math.ceil(variantsData.length / itemsPerPage);

  const handleItemSelection = (id: string) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleMasterCheckbox = () => {
    const allSelected = paginatedVariants.every((variant) =>
      selectedItems.includes(variant.id)
    );
    if (allSelected) {
      setSelectedItems((prevSelected) =>
        prevSelected.filter(
          (itemId) =>
            !paginatedVariants.some((variant) => variant.id === itemId)
        )
      );
    } else {
      const newSelections = paginatedVariants.map((variant) => variant.id);
      setSelectedItems((prevSelected) => [
        ...prevSelected,
        ...newSelections.filter((itemId) => !prevSelected.includes(itemId)),
      ]);
    }
  };

  const handleSelectAllItems = () => {
    if (selectedItems.length === variantsData.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(variantsData.map((variant) => variant.id));
    }
  };

  const getRowSpanCount = (index: number) => {
    if (!imagedAttributeCode) return 1;
    let count = 1;
    for (let i = index + 1; i < variantsData.length; i++) {
      if (
        variantsData[i][imagedAttributeCode]?.option_id ===
        variantsData[index][imagedAttributeCode]?.option_id
      ) {
        count++;
      } else {
        break;
      }
    }
    return count;
  };

  const handleApplyToAll = () => {
    const updatedVariants = variantsData.map((variant) => {
      if (selectedItems.length === 0 || selectedItems.includes(variant.id)) {
        return {
          ...variant,
          price: masterPrice || variant.price,
          quantity: masterQuantity || variant.quantity,
        };
      }
      return variant;
    });
    setVariantsData(updatedVariants);
    onUpdateVariant(updatedVariants);
  };

  const handleUpdateVariantImage = (variant: any, newImage: FileAttachment) => {
    if (!imagedAttributeCode) {
      console.error("No imaged attribute code is defined.");
      return; // Early return if imagedAttributeCode is not defined
    }

    const imagedAttributeValue = variant[imagedAttributeCode]?.value;

    const updatedVariants = variantsData.map((v) => {
      if (v[imagedAttributeCode]?.value === imagedAttributeValue) {
        return {
          ...v,
          image: newImage,
        };
      }
      return v;
    });

    setVariantsData(updatedVariants);
    onUpdateVariantImage(imagedAttributeCode, imagedAttributeValue, newImage); // Pass imagedAttributeCode to the parent function
  };
  // Utility function to create a composite key based on selected attributes
  const createCompositeKey = (
    variant: any,
    attributes: {
      attribute_code: string;
      frontend_label: string;
      is_imaged?: boolean;
    }[]
  ) => {
    return attributes
      .map((attr) => variant[attr.attribute_code]?.option_id || "")
      .join("-");
  };
  const handleRemoveVariantRow = (variant: any) => {
    const compositeKey = createCompositeKey(variant, selectedAttributes);
    const updatedVariants = variantsData.filter(
      (v) => createCompositeKey(v, selectedAttributes) !== compositeKey
    );

    setVariantsData(updatedVariants);
    onDeleteVariant(compositeKey); // Save updated variantsData
  };

  const handlePriceChange = (variantId: string, newPrice: string) => {
    const updatedVariants = variantsData.map((v) =>
      v.id === variantId ? { ...v, price: newPrice } : v
    );
    setVariantsData(updatedVariants);
    onUpdateVariant(updatedVariants);
  };
  const handleQuantityChange = (variantId: string, newQuantity: string) => {
    const updatedVariants = variantsData.map((v) =>
      v.id === variantId ? { ...v, quantity: newQuantity } : v
    );
    setVariantsData(updatedVariants);
    onUpdateVariant(updatedVariants);
  };
  const { t } = useTranslation();
  const columns = [
    {
      id: "select",
      header: (
        <input
          type="checkbox"
          checked={paginatedVariants.every((variant) =>
            selectedItems.includes(variant.id)
          )}
          onChange={handleMasterCheckbox}
          style={{ width: "100%" }}
        />
      ),
      cell: (variant: any) => (
        <td
          key={`select-${variant.id}`}
          style={{ width: "48px", textAlign: "center" }}
        >
          <input
            type="checkbox"
            checked={selectedItems.includes(variant.id)}
            onChange={() => handleItemSelection(variant.id)}
          />
        </td>
      ),
    },
    ...(imagedAttributeCode
      ? [
          {
            id: "image",
            header: t("table.header.image"),
            cell: (variant: any, rowIndex: number) => {
              const rowSpan = getRowSpanCount(rowIndex);
              return rowIndex === 0 ||
                variantsData[rowIndex][imagedAttributeCode]?.option_id !==
                  variantsData[rowIndex - 1][imagedAttributeCode]?.option_id ? (
                <td
                  key={`image-${variant.id}`}
                  rowSpan={rowSpan}
                  style={{ textAlign: "center" }}
                >
                  <VariantImage
                    image={variant.image}
                    onImageUpload={(newImage) =>
                      handleUpdateVariantImage(variant, newImage)
                    }
                  />
                </td>
              ) : null;
            },
          },
          ...selectedAttributes
            .filter((attr) => attr.is_imaged)
            .map((attr) => ({
              id: attr.attribute_code,
              header: t(attr.frontend_label),
              cell: (variant: any, rowIndex: number) => {
                const opt = variant[attr.attribute_code] || {};
                const rowSpan = getRowSpanCount(rowIndex);
                return rowIndex === 0 ||
                  variantsData[rowIndex][attr.attribute_code]?.option_id !==
                    variantsData[rowIndex - 1][attr.attribute_code]
                      ?.option_id ? (
                  <td
                    key={`${attr.attribute_code}-${variant.id}`}
                    rowSpan={rowSpan}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "var(--phoenix-choices-item-bg)",
                        borderRadius: "5px",
                        padding: "4px 8px",
                        width: "fit-content",
                      }}
                    >
                      {opt.swatch && (
                        <div
                          style={{
                            backgroundColor: opt.swatch,
                            width: "16px",
                            height: "16px",
                            marginRight: "8px",
                            border: "1px solid #ccc",
                          }}
                        />
                      )}
                      <span>{opt.value || ""}</span>
                    </div>
                  </td>
                ) : null;
              },
            })),
        ]
      : []),
    ...selectedAttributes
      .filter((attr) => !attr.is_imaged)
      .map((attr) => ({
        id: attr.attribute_code,
        header: t(attr.frontend_label),
        cell: (variant: any) => {
          const opt = variant[attr.attribute_code] || {};
          return (
            <td key={`${attr.attribute_code}-${variant.id}`}>
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "var(--phoenix-choices-item-bg)",
                  borderRadius: "5px",
                  padding: "4px 8px",
                  width: "fit-content",
                }}
              >
                <span>{opt.value || ""}</span>
              </div>
            </td>
          );
        },
      })),
    {
      id: "price",
      header: t("table.header.price"),
      cell: (variant: any) => (
        <td key={`price-${variant.id}`}>
          <InputGroup>
            <InputGroup.Text>{t("Dinar")}</InputGroup.Text>
            <FormControl
              type="number"
              size="sm"
              placeholder={t("table.placeholder.enterPrice")}
              value={variant.price || formValues.price || ""}
              onChange={(e) => handlePriceChange(variant.id, e.target.value)}
              style={{ maxWidth: "100px" }}
            />
          </InputGroup>
        </td>
      ),
    },
    ...(isExpedieParWamia
      ? []
      : [
          {
            id: "quantity",
            header: t("table.header.quantity"),
            cell: (variant: any) => (
              <td key={`quantity-${variant.id}`}>
                <Form.Control
                  size="sm"
                  type="number"
                  placeholder={t("table.placeholder.enterQuantity")}
                  value={variant.quantity}
                  onChange={(e) =>
                    handleQuantityChange(variant.id, e.target.value)
                  }
                  min="0"
                  style={{ maxWidth: "80px" }}
                />
              </td>
            ),
          },
        ]),
    {
      id: "delete",
      header: t("table.header.delete"),
      cell: (variant: any, rowIndex: number) => (
        <td key={`delete-${rowIndex}`}>
          <Button
            variant="danger"
            size="sm"
            className="btn-delete-small"
            onClick={() => handleRemoveVariantRow(variant)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </td>
      ),
    },
  ];

  return (
    <div>
      <Col xs={12} xl={12}>
        <div className="d-flex justify-content-between align-items-center mb-3 w-100">
          <div className="d-flex w-100">
            <Form className="d-flex w-100">
              <InputGroup
                className="mr-2"
                style={{ maxWidth: "200px", marginRight: "8px" }}
              >
                <InputGroup.Text>{t("Dinar")}</InputGroup.Text>
                <FormControl
                  size="sm"
                  type="number"
                  placeholder="Enter price"
                  value={masterPrice}
                  onChange={(e) => setMasterPrice(e.target.value)}
                />
              </InputGroup>
              {!isExpedieParWamia && (
                <Form.Control
                  type="number"
                  size="sm"
                  placeholder={t("table.placeholder.enterQuantity")}
                  value={masterQuantity}
                  onChange={(e) => setMasterQuantity(e.target.value)}
                  min="0"
                  className="mr-2"
                  style={{ maxWidth: "200px", marginRight: "10" }}
                />
              )}
              <div className="ml-auto" style={{ marginLeft: "10" }}>
                <Button
                  size="sm"
                  onClick={handleApplyToAll}
                  style={{ maxWidth: "200px", marginRight: "80px" }}
                >
                  {selectedItems.length > 0
                    ? t("table.actions.applyToSelected")
                    : t("table.actions.applyToAll")}
                </Button>
              </div>
            </Form>
          </div>
        </div>

        {selectedItems.length > 0 && (
          <div
            className="mb-2"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              textAlign: "left",
            }}
            onClick={handleSelectAllItems}
          >
            {`${selectedItems.length} / ${variantsData.length} Click to ${selectedItems.length === variantsData.length ? "deselect" : "select"} all items`}
          </div>
        )}
        <Col xs={12} xl={12} style={{ overflowX: "auto" }}>
          <Table striped bordered hover className="phoenix-table fs-9">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.id}
                    style={column.id === "select" ? { width: "48px" } : {}}
                  >
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedVariants.map((variant, rowIndex) => (
                <tr key={`variant-row-${variant.id}`}>
                  {columns.map((column) => column.cell(variant, rowIndex))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Col>
      <Row className="align-items-center">
        <Col xs={6}>
          <div className="text-muted">
            {t("table.pagination.showingRecords", {
              start: startIndex + 1,
              end: endIndex,
              total: variantsData.length,
            })}
          </div>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <TablePagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ProductVariantsTable;
