// src/config/productAttributes.tsx

import { Options } from "@testing-library/user-event";

// Define types directly in the productAttributes file
export interface Option {
  option_id: string;
  value?: string;
  swatch?: string;
}
export interface OptionsGetter {
  method: string;
  url: string;
  param: {
    key: string;
    value: string;
  };
  token: string;
  minlength: number;
  debounceTime: number;
}
export interface Attribute {
  attribute_id: string;
  attribute_code: string;
  frontend_label: string;
  is_imaged?: boolean;
  is_required: string;
  input_type: string;
  options_getter?: OptionsGetter;
  options?: Option[]; // Could be an array of options or 'loadFromApi'
}

export interface AttributeGroup {
  name: string;
  icon?: string; // Optional
  display?: string;
  attributes: Attribute[]; // Can be an array or a string like []
}

export interface ProductAttributes {
  CommonAttributes: {
    AttributeGroups: AttributeGroup[];
  };
  VariationAttributes: Attribute[];
}

// Full example of your JSON structure with translated frontend_label keys
const productAttributes: ProductAttributes = {
  CommonAttributes: {
    AttributeGroups: [
      {
        name: "Informations Générales",
        attributes: [
          {
            attribute_id: "73",
            attribute_code: "name",
            frontend_label: "attributes.productName", // Use translation key
            is_required: "1",
            input_type: "text",
          },
          {
            attribute_id: "105",
            attribute_code: "category_ids",
            frontend_label: "attributes.categories", // Use translation key
            is_required: "1",
            input_type: "cascader",
          },
          {
            attribute_id: "74",
            attribute_code: "sku",
            frontend_label: "attributes.sku", // Use translation key
            is_required: "1",
            input_type: "hidden",
          },
        ],
      },
      {
        name: "Infos Clés",
        icon: "globe",
        attributes: [
          {
            attribute_id: "608",
            attribute_code: "brand",
            frontend_label: "attributes.brand", // Use translation key
            is_required: "0",
            input_type: "select",
            options_getter: {
              method: "GET",
              url: "/attribute/options/search",
              param: {
                key: "searchQuery",
                value: "input",
              },
              token: "localStorage.getItem('authToken')",
              minlength: 2,
              debounceTime: 500,
            },
            options: [],
          },
          {
            attribute_id: "992",
            attribute_code: "Nom_du_modele",
            frontend_label: "attributes.modelName", // Use translation key
            is_required: "0",
            input_type: "text",
          },
          {
            attribute_id: "990",
            attribute_code: "Numero_du_modele",
            frontend_label: "attributes.modelNumber", // Use translation key
            is_required: "0",
            input_type: "text",
          },
          {
            attribute_id: "984",
            attribute_code: "reference",
            frontend_label: "attributes.sellerReference", // Use translation key
            is_required: "0",
            input_type: "text",
          },
          {
            attribute_id: "75",
            attribute_code: "short_description",
            frontend_label: "attributes.shortDescription", // Use translation key
            is_required: "1",
            input_type: "textarea",
          },
          {
            attribute_id: "76",
            attribute_code: "description",
            frontend_label: "attributes.description", // Use translation key
            is_required: "1",
            input_type: "textarea",
          },
        ],
      },
      {
        name: "Images",
        icon: "picture",
        attributes: [
          {
            attribute_id: "48",
            attribute_code: "image",
            frontend_label: "attributes.image", // Use translation key
            is_required: "0",
            input_type: "image",
          },
          {
            attribute_id: "96",
            attribute_code: "gallery",
            frontend_label: "attributes.imageGallery", // Use translation key
            is_required: "0",
            input_type: "gallery",
          },
        ],
      },
      {
        name: "Caractéristiques",
        icon: "sliders",
        attributes: [],
      },
      {
        name: "Offre",
        icon: "tag",
        display: "inTab",
        attributes: [
          {
            attribute_id: "77",
            attribute_code: "price",
            frontend_label: "attributes.price", // Use translation key
            is_required: "1",
            input_type: "price",
          },
          {
            attribute_id: "78",
            attribute_code: "special_price",
            frontend_label: "attributes.specialPrice", // Use translation key
            is_required: "0",
            input_type: "price",
          },
          {
            attribute_id: "79",
            attribute_code: "special_from_date",
            frontend_label: "attributes.specialPriceFromDate", // Use translation key
            is_required: "0",
            input_type: "date",
          },
          {
            attribute_id: "80",
            attribute_code: "special_to_date",
            frontend_label: "attributes.specialPriceToDate", // Use translation key
            is_required: "0",
            input_type: "date",
          },
          {
            attribute_id: "1062",
            attribute_code: "Livraison_gratuite",
            frontend_label: "attributes.freeShipping", // Use translation key
            is_required: "1",
            input_type: "select",
            options: [
              {
                option_id: "17952",
                value: "Oui",
              },
              {
                option_id: "17953",
                value: "Non",
              },
            ],
          },
          {
            attribute_id: "1063",
            attribute_code: "politique_de_retour",
            frontend_label: "attributes.returnPolicy", // Use translation key
            is_required: "1",
            input_type: "select",
            options: [
              {
                option_id: "17954",
                value: "30 jours",
              },
              {
                option_id: "17955",
                value: "14 jours",
              },
              {
                option_id: "17956",
                value: "Non éligible",
              },
            ],
          },
          {
            attribute_id: "643",
            attribute_code: "garantie",
            frontend_label: "attributes.warranty", // Use translation key
            is_required: "0",
            input_type: "select",
            options: [
              {
                option_id: "10278",
                value: "Non",
              },
              {
                option_id: "16634",
                value: "6 mois",
              },
              {
                option_id: "10277",
                value: "1 an",
              },
              {
                option_id: "18371",
                value: "18 mois",
              },
              {
                option_id: "10279",
                value: "2 ans",
              },
              {
                option_id: "16609",
                value: "3 ans",
              },
              {
                option_id: "17807",
                value: "5 ans",
              },
              {
                option_id: "17808",
                value: "10 ans",
              },
              {
                option_id: "17809",
                value: "à vie",
              },
            ],
          },
        ],
      },
      {
        name: "Informations Logistiques",
        icon: "truck",
        display: "inTab",
        attributes: [
          {
            attribute_id: "983",
            attribute_code: "mode_expedition",
            frontend_label: "attributes.shippingMethod", // Use translation key
            is_required: "1",
            input_type: "select",
            options: [
              {
                option_id: "16500",
                value: "Expédié par Wamia",
              },
              {
                option_id: "16501",
                value: "Livraison Facile Par Vendeur",
              },
              {
                option_id: "18383",
                value: "Livraison Facile Par Wamia",
              },
            ],
          },
          {
            attribute_id: "115",
            attribute_code: "quantity_and_stock_status",
            frontend_label: "attributes.quantity", // Use translation key
            is_required: "0",
            input_type: "text",
          },
          {
            attribute_id: "82",
            attribute_code: "weight",
            frontend_label: "attributes.weight", // Use translation key
            is_required: "1",
            input_type: "weight",
          },
          {
            attribute_id: "707",
            attribute_code: "dimensions_de_l_outil_largeur1",
            frontend_label: "attributes.packageWidth", // Use translation key
            is_required: "1",
            input_type: "text",
          },
          {
            attribute_id: "713",
            attribute_code: "dimensions_de_l_outil_hauteur1",
            frontend_label: "attributes.packageHeight", // Use translation key
            is_required: "1",
            input_type: "text",
          },
          {
            attribute_id: "880",
            attribute_code: "longueur25",
            frontend_label: "attributes.packageDepth", // Use translation key
            is_required: "1",
            input_type: "text",
          },
        ],
      },
    ],
  },
  VariationAttributes: [
    {
      attribute_id: "146",
      attribute_code: "couleur",
      frontend_label: "attributes.color", // Correct translation key
      is_imaged: true,
      is_required: "0",
      input_type: "select",
      options: [],
    },
    {
      attribute_id: "157",
      attribute_code: "pointure",
      frontend_label: "attributes.pointure", // Correct translation key
      is_required: "0",
      input_type: "select",
      options: [],
    },
    {
      attribute_id: "611",
      attribute_code: "size",
      frontend_label: "attributes.size", // Correct translation key
      is_required: "0",
      input_type: "select",
      options: [],
    },
  ],
};

export default productAttributes;
// VariationAttributes: [
//   {
//     attribute_id: "146",
//     attribute_code: "couleur",
//     frontend_label: "Couleur",
//     is_imaged: true,
//     is_required: "0",
//     input_type: "select",
//     options: [],
//   },
//   {
//     attribute_id: "157",
//     attribute_code: "pointure",
//     frontend_label: "Pointure ",
//     is_required: "0",
//     input_type: "select",
//     options: [],
//   },
//   {
//     attribute_id: "611",
//     attribute_code: "size",
//     frontend_label: "Taille",
//     is_required: "0",
//     input_type: "select",
//     options: [],
//   },
// ],
