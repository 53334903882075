import React, { useEffect } from "react";
import { Container, Card } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import { useTranslation } from "react-i18next";

const SecurityPage: React.FC = () => {
  const {
    config: { theme },
  } = useAppContext();

  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.remove("light", "dark", "auto");
    document.body.classList.add(theme);
  }, [theme]);

  return (
    <Container fluid className={`settings-page ${theme} px-0`}>
      <div className="content d-flex flex-column align-items-center justify-content-start vh-100">
        <h2 className="mt-4 mb-4">{t("securityTitle")}</h2>
        <Card className="shadow-lg w-50">
          <Card.Body className="text-center">
            <p className="text-muted mt-3">{t("securityDescription")}</p>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default SecurityPage;
