import classNames from "classnames";
import ChatWidget from "components/common/chat-widget/ChatWidget";
import Footer from "components/footers/Footer";
import NavbarDual from "components/navbars/navbar-dual/NavbarDual";
import NavbarTopHorizontal from "components/navbars/navbar-horizontal/NavbarTopHorizontal";
import NavbarTopDefault from "components/navbars/navbar-top/NavbarTopDefault";
import NavbarVertical from "components/navbars/navbar-vertical/NavbarVertical";
import { useAppContext } from "providers/AppProvider";
import { useAuth } from "providers/AuthProvider"; // Import useAuth
import { useMainLayoutContext } from "providers/MainLayoutProvider";
import { Container } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";

const MainLayout = () => {
  const {
    config: { navbarPosition },
  } = useAppContext();

  const { seller } = useAuth(); // Retrieve seller from AuthProvider
  const { contentClass, footerClass } = useMainLayoutContext();
  const location = useLocation();

  // Define paths where the sidebar should not be shown
  const restrictedPaths = ["/restricted"];

  // Determine whether to hide the sidebar
  const hideNavbarVertical =
    restrictedPaths.includes(location.pathname) || seller?.is_seller !== "1";

  return (
    <Container fluid className="px-0">
      {!hideNavbarVertical &&
        (navbarPosition === "vertical" || navbarPosition === "combo") && (
          <NavbarVertical />
        )}
      {navbarPosition === "vertical" && <NavbarTopDefault />}
      {(navbarPosition === "horizontal" || navbarPosition === "combo") && (
        <NavbarTopHorizontal />
      )}
      {navbarPosition === "dual" && <NavbarDual />}
      <div className={classNames(contentClass, "content")}>
        <Outlet />
        <Footer className={classNames(footerClass, "position-absolute")} />
      </div>
    </Container>
  );
};

export default MainLayout;
