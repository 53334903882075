import React, { useState, CSSProperties } from "react";
import { Card, Toast, ToastContainer } from "react-bootstrap";
import AvatarUpload from "components/common/AvatarUpload";
import CoverUpload from "components/common/CoverUpload";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useAppContext } from "providers/AppProvider";
import { useTranslation } from "react-i18next";
import coverPlaceholder from "assets/img/profileAndCover/Cover.jpg";
import profilePlaceholder from "assets/img/profileAndCover/Profil.jpg";
import api from "api/api";

interface SellerProfileCardProps {
  seller: {
    name: string;
    company_locality: string;
    shop_url: string;
    shop_title: string;
    total_spent?: string;
    last_order?: string;
    total_orders?: string;
    logo_pic?: string;
    banner_pic?: string;
    company_description?: string;
  };
  onUpdateSellerInfo: (
    updatedTitle: string,
    updatedDescription: string
  ) => void;
}

const SellerProfileCard: React.FC<SellerProfileCardProps> = ({
  seller,
  onUpdateSellerInfo,
}) => {
  const { t } = useTranslation();
  const {
    config: { isDark },
  } = useAppContext();
  const [coverPhoto, setCoverPhoto] = useState<string | null>(
    seller.banner_pic
      ? `${process.env.REACT_APP_MEDIA_SERVER_API_URL}/avatar/${seller.banner_pic}`
      : coverPlaceholder
  );

  const [avatarLoading, setAvatarLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState<"success" | "error">(
    "success"
  );
  const [sellerData, setSeller] = useState(seller);

  const handleAvatarChange = async (file: File | null) => {
    if (!file) return;

    setAvatarLoading(true);
    const originalLogo = sellerData.logo_pic;

    try {
      const formData = new FormData();
      formData.append("logo_pic", file);

      const authToken = localStorage.getItem("authToken");

      const response = await api.post("/seller/editprofile", formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      handleApiResponse(response.data, "logo_pic", file);
    } catch (error) {
      console.error("Error uploading avatar:", error);
      setToastMessage(t("updateError"));
      setToastVariant("error");
      setShowToast(true);
      setSeller({ ...sellerData, logo_pic: originalLogo });
    } finally {
      setAvatarLoading(false);
    }
  };

  const handleCoverChange = async (file: File | null) => {
    if (!file) return;

    setCoverLoading(true);
    const originalBanner = sellerData.banner_pic;

    try {
      const formData = new FormData();
      formData.append("banner_pic", file);

      const authToken = localStorage.getItem("authToken");

      const response = await api.post("/seller/editprofile", formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      handleApiResponse(response.data, "banner_pic", file);
    } catch (error) {
      console.error("Error uploading cover photo:", error);
      setToastMessage(t("updateError"));
      setToastVariant("error");
      setShowToast(true);
      setSeller({ ...sellerData, banner_pic: originalBanner });
    } finally {
      setCoverLoading(false);
    }
  };

  const handleApiResponse = (data: any, field: string, file: File) => {
    if (data && data[0]?.success) {
      setToastMessage(t("updateSuccess"));
      setToastVariant("success");
      setShowToast(true);

      const updatedSeller = {
        ...JSON.parse(localStorage.getItem("seller") || "{}"),
        ...data[0].data,
      };
      localStorage.setItem("seller", JSON.stringify(updatedSeller));
      setSeller(updatedSeller);

      if (field === "banner_pic") {
        setCoverPhoto(
          `${process.env.REACT_APP_MEDIA_SERVER_API_URL}/avatar/${data[0].data.banner_pic}`
        );
      } else if (field === "logo_pic") {
        setSeller({ ...updatedSeller, logo_pic: data[0].data.logo_pic });
      }

      if (data[0].data.shop_title || data[0].data.company_description) {
        onUpdateSellerInfo(
          data[0].data.shop_title,
          data[0].data.company_description
        );
      }
    } else {
      setToastMessage(data[0]?.message || t("updateError"));
      setToastVariant("error");
      setShowToast(true);
    }
  };

  const styles: { [key: string]: CSSProperties } = {
    coverPhoto: {
      position: "relative",
      width: "100%",
      height: "232px",
      overflow: "hidden",
      borderRadius: "0.5rem",
    },
    profileSection: {
      position: "relative",
      top: "-32px",
      textAlign: "left",
      display: "flex",
      alignItems: "center",
    },
    statsSection: {
      paddingTop: "20px",
      marginTop: "20px",
      borderTop: "1px solid #eee",
    },
  };

  return (
    <>
      <Card className="h-100">
        <div style={styles.coverPhoto}>
          {coverLoading ? (
            <Skeleton
              height="232px"
              baseColor="var(--phoenix-body-bg)"
              highlightColor={isDark ? "#444" : "#eee"}
            />
          ) : (
            coverPhoto && (
              <CoverUpload
                src={coverPhoto}
                className="w-100"
                onChange={handleCoverChange}
                aria-label={t("uploadCover")}
              />
            )
          )}
        </div>
        <Card.Body>
          <div style={styles.profileSection}>
            <div
              style={{
                borderRadius: "50%",
                border: "4px solid var(--phoenix-emphasis-bg)",
              }}
            >
              {avatarLoading ? (
                <Skeleton
                  circle
                  height="9.375rem"
                  width="9.375rem"
                  baseColor="var(--phoenix-body-bg)"
                  highlightColor={isDark ? "#444" : "#eee"}
                />
              ) : (
                <AvatarUpload
                  size="5xl"
                  src={
                    sellerData.logo_pic
                      ? `${process.env.REACT_APP_MEDIA_SERVER_API_URL}/avatar/${sellerData.logo_pic}`
                      : profilePlaceholder
                  }
                  onChange={handleAvatarChange}
                  aria-label={t("uploadAvatar")}
                />
              )}
            </div>
            <div style={{ padding: "16px" }}>
              <h3>{sellerData.shop_title}</h3>
              <p className="text-body-secondary">
                {sellerData.company_description}
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>

      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ position: "fixed", top: "1rem", right: "1rem", zIndex: 9999 }}
      >
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          bg={toastVariant === "success" ? "success" : "danger"}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default SellerProfileCard;
