import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Collapse, Nav } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Route } from "sitemap";
import { capitalize } from "helpers/utils";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useNavbarVerticalCollapse } from "./NavbarVerticalCollapseProvider";
import Badge from "components/base/Badge";
import { useAppContext } from "providers/AppProvider";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

interface NavbarVerticalMenuProps {
  routes: Route[];
  level: number;
  t: TFunction;
}

interface NavItemProps {
  route: Route;
  level: number;
  t: TFunction;
}

const NavItem = ({ route, level, t }: NavItemProps) => {
  const {
    config: { isNavbarVerticalCollapsed },
  } = useAppContext();
  const { setOpenItems, openItems } = useNavbarVerticalCollapse();

  return (
    <Nav.Item as="li">
      <NavLink
        to={route.path || "#!"}
        className={({ isActive }) =>
          classNames("nav-link", {
            "label-1": level === 1,
            active: isActive && route.path !== "#!",
          })
        }
        onClick={() => level === 1 && setOpenItems(openItems.map(() => ""))}
      >
        <div
          className={classNames("d-flex align-items-center", {
            "text-body-quaternary": !route.active,
          })}
        >
          {route.icon ? (
            <>
              <span
                className={classNames("nav-link-icon", {
                  new: route.new || route.hasNew,
                })}
              >
                {route.iconSet === "font-awesome" ? (
                  <FontAwesomeIcon
                    icon={route.icon as IconProp}
                    transform={{ size: 16 }}
                  />
                ) : (
                  <FeatherIcon icon={route.icon} size={16} />
                )}
              </span>
              <span className="nav-link-text-wrapper">
                <span className="nav-link-text">{t(route.name)}</span>
                {route.new && !isNavbarVerticalCollapsed && (
                  <Badge variant="phoenix" bg="warning" className="ms-2">
                    {t("New")}
                  </Badge>
                )}
                {route.next && !isNavbarVerticalCollapsed && (
                  <Badge variant="phoenix" bg="primary" className="ms-2">
                    {t("Next")}
                  </Badge>
                )}
              </span>
            </>
          ) : (
            <>
              <span className="nav-link-text">{t(capitalize(route.name))}</span>
              {route.new && (
                <Badge variant="phoenix" bg="warning" className="ms-2">
                  {t("New")}
                </Badge>
              )}
              {route.next && (
                <Badge variant="phoenix" bg="primary" className="ms-2">
                  {t("Next")}
                </Badge>
              )}
            </>
          )}
        </div>
      </NavLink>
    </Nav.Item>
  );
};

const CollapsableNavItem = ({ route, level, t }: NavItemProps) => {
  const { pathname } = useLocation();
  const { setOpenItems, openItems } = useNavbarVerticalCollapse();
  const {
    config: { isNavbarVerticalCollapsed },
  } = useAppContext();

  const openCollapse = (childrens: Route[] = []) => {
    const checkLink = (children: Route) =>
      `${children.path}` === pathname ||
      (children.pages && children.pages.some(checkLink));
    return childrens.some(checkLink);
  };

  const updateOpenItems = (name: string) => {
    const updatedOpenItems = [...openItems];
    updatedOpenItems[level] = name;
    updatedOpenItems.forEach((item, index) => {
      if (index > level) {
        updatedOpenItems[index] = "";
      }
    });
    setOpenItems(updatedOpenItems);
  };

  useEffect(() => {
    if (openCollapse(route.pages)) {
      updateOpenItems(route.name);
    }
  }, []);

  return (
    <>
      <Nav.Link
        onClick={() => {
          if (route.name === openItems[level]) {
            updateOpenItems("");
          } else {
            updateOpenItems(route.name);
          }
        }}
        className={classNames("dropdown-indicator", {
          "label-1": level === 1,
          collapsed: openItems[level] !== route.name,
          "text-body-quaternary": !route.active,
        })}
        aria-expanded={openItems[level] === route.name}
      >
        <div className="d-flex align-items-center">
          <div className="dropdown-indicator-icon">
            <FontAwesomeIcon
              icon={faCaretRight}
              className={classNames({
                "text-body-quaternary": !route.active,
              })}
            />
          </div>
          {level === 1 && (
            <span
              className={classNames("nav-link-icon", {
                new: route.new || route.hasNew,
              })}
            >
              <FeatherIcon icon={route.icon} size={16} />
            </span>
          )}
          <span
            className={classNames("nav-link-text", {
              new: route.hasNew,
            })}
          >
            {t(capitalize(route.name))}
            {(!isNavbarVerticalCollapsed || level !== 1) && route.new && (
              <Badge variant="phoenix" bg="warning" className="ms-2">
                {t("New")}
              </Badge>
            )}
            {(!isNavbarVerticalCollapsed || level !== 1) && route.next && (
              <Badge variant="phoenix" bg="primary" className="ms-2">
                {t("Next")}
              </Badge>
            )}
          </span>
        </div>
      </Nav.Link>
      <div
        className={classNames("parent-wrapper", {
          "label-1": level === 1,
        })}
      >
        <Collapse in={openItems[level] === route.name} className="nav parent">
          <div>
            {level === 1 && (
              <div className="collapsed-nav-item-title d-none">
                {t(capitalize(route.name))}
                {isNavbarVerticalCollapsed && route.new && (
                  <Badge variant="phoenix" bg="warning" className="ms-2">
                    {t("New")}
                  </Badge>
                )}
                {isNavbarVerticalCollapsed && route.next && (
                  <Badge variant="phoenix" bg="primary" className="ms-2">
                    {t("Next")}
                  </Badge>
                )}
              </div>
            )}
            <NavbarVerticalMenu
              routes={route.pages || []}
              level={level + 1}
              t={t}
            />
          </div>
        </Collapse>
      </div>
    </>
  );
};

const NavbarVerticalMenu = ({ routes, level, t }: NavbarVerticalMenuProps) => {
  return (
    <>
      {routes.map((route) => (
        <div key={route.name}>
          {level === 1 ? (
            <div className="nav-item-wrapper">
              {route.pages ? (
                <CollapsableNavItem route={route} level={level} t={t} />
              ) : (
                <NavItem route={route} level={level} t={t} />
              )}
            </div>
          ) : (
            <>
              {route.pages ? (
                <CollapsableNavItem route={route} level={level} t={t} />
              ) : (
                <NavItem route={route} level={level} t={t} />
              )}
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default NavbarVerticalMenu;
