import React, { useState, useEffect } from "react";
import ReactSelect, { Props as ReactSelectProps } from "react-select";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CSSObjectWithLabel } from "react-select";
import { Attribute, Option } from "config/productAttributes";
import { useTranslation } from "react-i18next";

interface ProductVariantProps {
  className?: string;
  index: number;
  options: Attribute[];
  onRemove: () => void;
  handleChange: (attribute_code: string, value: any) => void;
  formValues: { [key: string]: any };
  disableRemoveButton?: boolean;
}

interface OptionType {
  value: string;
  label: string;
  swatch?: string;
}

const ProductVariant: React.FC<ProductVariantProps> = ({
  className,
  index,
  options,
  onRemove,
  handleChange,
  formValues,
  disableRemoveButton,
}) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [selectedReactSelectValues, setSelectedReactSelectValues] = useState<
    OptionType[]
  >([]);
  const [selectedAttributeId, setSelectedAttributeId] = useState<
    string | undefined
  >();

  useEffect(() => {
    const storedAttribute = formValues[`variantAttribute_${index}`];
    if (storedAttribute) {
      setSelectedAttributeId(storedAttribute.attribute_id);
      const selectedAttribute = options.find(
        (option) => option.attribute_id === storedAttribute.attribute_id
      );
      if (selectedAttribute) {
        setSelectedOptions(selectedAttribute.options || []);
      }
    }

    const storedSelectedOptions =
      formValues[`selectedAttributeOptions_${index}`];
    if (storedSelectedOptions) {
      setSelectedReactSelectValues(
        storedSelectedOptions.map((opt: Option) => ({
          value: opt.option_id,
          label: opt.value || "", // Ensure label is a string
          swatch: opt.swatch,
        }))
      );
    }
  }, [formValues, index, options]);

  const customStyles = {
    control: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      height: "auto",
      alignItems: "flex-start",
      color: "var(--phoenix-body-color)",
    }),
    dropdownIndicator: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      display: "none",
    }),
    option: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      display: "flex",
      alignItems: "center",
    }),
    multiValue: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      display: "flex",
      alignItems: "center",
      color: "var(--phoenix-body-color)",
    }),
  };

  const handleAttributeSelect = (inputId: string, attributeId: string) => {
    const selectedAttribute = options.find(
      (option) => option.attribute_id === attributeId
    );
    if (selectedAttribute) {
      setSelectedOptions(selectedAttribute.options || []);
      setSelectedReactSelectValues([]);
      handleChange(inputId, selectedAttribute);
    } else {
      setSelectedOptions([]);
      setSelectedReactSelectValues([]);
    }
  };

  const customReactSelectProps: ReactSelectProps<OptionType, true> = {
    options: selectedOptions.map((opt) => ({
      value: opt.option_id,
      label: opt.value || "", // Ensure label is always a string
      swatch: opt.swatch,
    })),
    value: selectedReactSelectValues,
    isMulti: true,
    placeholder: t("selectAttributeOptions"),
    styles: customStyles as any,
    formatOptionLabel: (e) => (
      <div className="d-flex align-items-center">
        {e.swatch && (
          <div
            style={{
              backgroundColor: e.swatch,
              width: "16px",
              height: "16px",
              marginRight: "8px",
              border: "1px solid #ccc",
            }}
          />
        )}
        <span>{e.label}</span>
      </div>
    ),
    getOptionValue: (e) => e.value,
    components: {
      MultiValueLabel: ({ data }) => (
        <div className="d-flex align-items-center">
          {data.swatch && (
            <div
              style={{
                backgroundColor: data.swatch,
                width: "16px",
                height: "16px",
                marginRight: "8px",
                border: "1px solid #ccc",
              }}
            />
          )}
          <span>{data.label}</span>
        </div>
      ),
    },
    closeMenuOnSelect: false, // Keeps the menu open after selecting an option
    onChange: (newValue, actionMeta) => {
      const selected = newValue as OptionType[]; // Explicitly cast to OptionType[]
      setSelectedReactSelectValues(selected);
      const selectedOptionsArray = selected.map((opt) => {
        const matchingOption = selectedOptions.find(
          (option) => option.option_id === opt.value
        );
        return {
          option_id: opt.value,
          value: matchingOption?.value || "",
          swatch: matchingOption?.swatch || "",
        };
      });
      handleChange(`selectedAttributeOptions_${index}`, selectedOptionsArray);
    },
  };

  return (
    <div className={className}>
      <div className="d-flex gap-2 mb-2">
        <h5 className="mb-0 text-body-highlight">{t("option")}</h5>
        <Link
          className={`fw-bold fs-9 ${disableRemoveButton ? "disabled" : ""}`}
          to="#!"
          onClick={(e) => {
            if (!disableRemoveButton) {
              e.preventDefault();
              onRemove();
            }
          }}
          style={{
            pointerEvents: disableRemoveButton ? "none" : "auto",
            color: disableRemoveButton ? "#ccc" : "inherit",
          }}
        >
          {t("remove")}
        </Link>
      </div>
      <Form.Select
        className="mb-3"
        id={`variantAttribute_${index}`}
        value={selectedAttributeId}
        onChange={(e) => {
          handleAttributeSelect(`variantAttribute_${index}`, e.target.value);
          setSelectedAttributeId(e.target.value);
          setSelectedReactSelectValues([]);
          handleChange(`selectedAttributeOptions_${index}`, []);
        }}
      >
        <option value="">{t("selectOption")}</option>
        {options.map((option) => (
          <option key={option.attribute_code} value={option.attribute_id}>
            {t(`${option.frontend_label.toLowerCase()}`)}
          </option>
        ))}
      </Form.Select>
      <ReactSelect {...customReactSelectProps} />
    </div>
  );
};

export default ProductVariant;
