import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

const DashboardRoute: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const { seller, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/pages/authentication/simple/sign-in" />;
  }

  if (seller?.is_seller !== "1") {
    return <Navigate to="/restricted" />;
  }

  return children;
};

export default DashboardRoute;
