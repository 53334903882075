import React from "react";
import { useAppContext } from "../providers/AppProvider";
import darkBg from "../assets/img/verif-in-prog/Dark BG.svg";
import lightBg from "../assets/img/verif-in-prog/light BG.svg";

const LimitedAccess = () => {
  const { config } = useAppContext();

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>Access Limited</h1>
      <p>
        You don't have permission to view this page. Please contact support for
        more information.
      </p>
      <img
        src={config.isDark ? darkBg : lightBg}
        alt="Verification in Progress"
        style={{ maxWidth: "100%", height: "auto" }}
      />
    </div>
  );
};

export default LimitedAccess;
