import { Nav, Navbar } from "react-bootstrap";
import { routes } from "sitemap";
import { routes as routes2 } from "./SettingSide";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import {
  UilArrowFromRight,
  UilLeftArrowToLeft,
} from "@iconscout/react-unicons";
import { useAppContext } from "providers/AppProvider";
import Button from "components/base/Button";
import NavbarTopNav from "../navbar-horizontal/NavbarTopNav";
import { useBreakpoints } from "providers/BreakpointsProvider";
import NavbarVerticalCollapseProvider from "./NavbarVerticalCollapseProvider";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavbarVertical = () => {
  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarVerticalAppearance,
      isNavbarVerticalCollapsed,
    },
    setConfig,
  } = useAppContext();

  const { breakpoints } = useBreakpoints();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  const isSettingPage = pathname.startsWith("/apps/e-commerce/admin/setting");

  return (
    <NavbarVerticalCollapseProvider>
      <Navbar
        className="navbar-vertical" // Removed alignment logic
        expand="lg"
        variant=""
        data-navbar-appearance={
          navbarVerticalAppearance === "darker" ? "darker" : ""
        }
      >
        <Navbar.Collapse id="navbarVerticalCollapse" in={openNavbarVertical}>
          <div className="navbar-vertical-content">
            <Nav
              className="flex-column" // Removed alignment logic
              as="ul"
              id="navbarVerticalNav"
            >
              {(isSettingPage ? routes2 : routes).map((route) => (
                <Nav.Item key={route.label}>
                  {!route.labelDisabled && (
                    <>
                      <p className="navbar-vertical-label">{t(route.label)}</p>
                      <hr className="navbar-vertical-line" />
                    </>
                  )}
                  <NavbarVerticalMenu
                    level={1}
                    routes={route.pages}
                    t={t} // Pass the translation function
                  />
                </Nav.Item>
              ))}
            </Nav>

            {navbarPosition === "combo" && breakpoints.down("lg") && (
              <div className="move-container">
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr" />
                </div>
                <NavbarTopNav />
              </div>
            )}
          </div>
        </Navbar.Collapse>
        <div className="navbar-vertical-footer">
          <Button
            className="navbar-vertical-toggle border-0 fw-semibold w-100 white-space-nowrap d-flex align-items-center"
            onClick={() => {
              setConfig({
                isNavbarVerticalCollapsed: !isNavbarVerticalCollapsed,
              });
            }}
          >
            {isNavbarVerticalCollapsed ? (
              <UilArrowFromRight size={16} className="mb-1" />
            ) : (
              <>
                <UilLeftArrowToLeft size={16} className="mb-1" />
                <span className="ms-2">{t("collapsed view")}</span>
              </>
            )}
          </Button>
        </div>
      </Navbar>
    </NavbarVerticalCollapseProvider>
  );
};

export default NavbarVertical;
