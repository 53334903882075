import React, { useState, useEffect, useRef } from "react";
import { Card, Nav, Button, Dropdown } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useAuth } from "providers/AuthProvider";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import profilePlaceholder from "assets/img/profileAndCover/Profil.jpg";

interface NavItem {
  label: string;
  icon: string;
  link?: string;
}

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const [isLanguageExpanded, setIsLanguageExpanded] = useState(false);
  const { logout, seller } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);

  const navItems: NavItem[] = [
    { label: "profile", icon: "user", link: "/seller/profile" },
    { label: "dashboard", icon: "pie-chart", link: "/" },
    {
      label: "settings_privacy",
      icon: "settings",
      link: "/apps/e-commerce/admin/setting/theme",
    },
    { label: "help_center", icon: "help-circle" },
  ];

  useEffect(() => {
    const handleLanguageChange = () =>
      console.log("Language changed to:", i18n.language);
    i18n.on("languageChanged", handleLanguageChange);

    return () => i18n.off("languageChanged", handleLanguageChange);
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/pages/authentication/simple/sign-in");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const toggleLanguageMenu = () => {
    setIsLanguageExpanded((prev) => {
      const isExpanding = !prev;

      // Scroll to bottom when expanding
      if (isExpanding && scrollRef.current) {
        scrollRef.current.style.overflow = "auto"; // Enable scrolling
        setTimeout(() => {
          scrollRef.current?.scrollTo({
            top: scrollRef.current.scrollHeight, // Scroll to the bottom
            behavior: "smooth",
          });
        }, 0); // Ensure it runs after the DOM updates
      }

      // Disable scrolling when collapsing
      if (!isExpanding && scrollRef.current) {
        scrollRef.current.style.overflow = "hidden";
        scrollRef.current.scrollTo({
          top: 0, // Scroll to top when collapsing
          behavior: "smooth",
        });
      }

      return isExpanding;
    });
  };

  const handleLanguageChange = (language: string) => {
    i18n
      .changeLanguage(language)
      .then(() => console.log(`Language switched to: ${language}`))
      .catch((err) => console.error("Error switching language:", err));

    setIsLanguageExpanded(false);
    if (scrollRef.current) {
      scrollRef.current.style.overflow = "hidden";
      scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        "navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border"
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          {/* Profile Header */}
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <img
              src={
                seller?.logo_pic
                  ? `${process.env.REACT_APP_MEDIA_SERVER_API_URL}/avatar/${seller.logo_pic}`
                  : profilePlaceholder
              }
              alt="Avatar"
              style={{ borderRadius: "50%", width: "80px", height: "80px" }}
            />
            <h6 className="text-body-emphasis">{seller?.shop_title}</h6>
          </div>

          {/* Scrollable Content */}
          <div
            ref={scrollRef}
            style={{
              height: "10rem",
              overflow: "hidden",
              transition: "overflow 0.2s ease",
            }}
          >
            <Nav className="nav flex-column mb-2 pb-1">
              {navItems.map((item) => (
                <Nav.Item key={item.label}>
                  <Link to={item.link || "#!"} className="nav-link px-3">
                    <FeatherIcon
                      icon={item.icon}
                      size={16}
                      className="me-2 text-body"
                    />
                    <span className="text-body-highlight">{t(item.label)}</span>
                  </Link>
                </Nav.Item>
              ))}

              {/* Language Toggle */}
              <Nav.Item>
                <Button
                  variant="link"
                  className="nav-link px-3 text-start w-100 d-flex align-items-center justify-content-between"
                  onClick={toggleLanguageMenu}
                  aria-expanded={isLanguageExpanded}
                >
                  <span>
                    <FeatherIcon
                      icon="globe"
                      size={16}
                      className="me-2 text-body"
                    />
                    {t("language_label")}
                  </span>
                  <FeatherIcon
                    icon="chevron-down"
                    size={16}
                    className={classNames("ms-2", {
                      "rotate-180": isLanguageExpanded,
                    })}
                    style={{ transition: "transform 0.2s ease" }}
                  />
                </Button>
              </Nav.Item>
              {isLanguageExpanded && (
                <>
                  <Nav.Item>
                    <Button
                      variant="light"
                      className="nav-link px-3 text-start w-100"
                      onClick={() => handleLanguageChange("fr")}
                    >
                      <img
                        src="https://flagcdn.com/w40/fr.png"
                        alt="French"
                        className="me-2"
                        style={{ width: "20px", height: "15px" }}
                      />
                      {t("french")}
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="light"
                      className="nav-link px-3 text-start w-100"
                      onClick={() => handleLanguageChange("ar")}
                    >
                      <img
                        src="https://flagcdn.com/w40/tn.png"
                        alt="Arabic"
                        className="me-2"
                        style={{ width: "20px", height: "15px" }}
                      />
                      {t("arabic")}
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="light"
                      className="nav-link px-3 text-start w-100"
                      onClick={() => handleLanguageChange("en")}
                    >
                      <img
                        src="https://flagcdn.com/w40/us.png"
                        alt="English"
                        className="me-2"
                        style={{ width: "20px", height: "15px" }}
                      />
                      {t("english")}
                    </Button>
                  </Nav.Item>
                </>
              )}
            </Nav>
          </div>
        </Card.Body>

        {/* Footer */}
        <Card.Footer className="p-0 border-top border-translucent">
          <hr />
          <div className="px-3">
            <button
              onClick={handleLogout}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              {t("sign_out")}
            </button>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
            <Link className="text-body-quaternary me-1" to="#!">
              {t("privacy_policy")}
            </Link>
            •
            <Link className="text-body-quaternary mx-1" to="#!">
              {t("terms")}
            </Link>
            •
            <Link className="text-body-quaternary ms-1" to="#!">
              {t("cookies")}
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
