import axios from "axios";
import { log } from "console";

// Create Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_MAGENTO_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Add request interceptor
// Add response interceptor
api.interceptors.response.use(
  (response) => response, // Pass successful responses
  (error) => {
    if (error.response) {
      // Server responded but with an error status code
      console.error("Response Error:", error.response.status);
      console.error("Error Data:", error.response.data);

      // Handle specific status codes
      if (error.response.status === 401) {
        console.warn(
          "401 Unauthorized - Deleting token and seller, redirecting"
        );
        localStorage.removeItem("authToken");
        localStorage.removeItem("seller");
        window.location.href = "/pages/authentication/simple/sign-in";
      }
    } else if (error.request) {
      // Request was made but no response was received
      console.error("Request Error: No response received");
      console.error("Request Details:", error.request);

      if (error.code === "ERR_NETWORK") {
        console.warn("Network Error - Deleting token and seller, redirecting");
        localStorage.removeItem("authToken");
        localStorage.removeItem("seller");
        window.location.href = "/pages/authentication/simple/sign-in";
      }
    } else {
      // Something happened in setting up the request
      console.error("General Error:", error.message);
    }

    console.warn("Full Error Object:", error);

    return Promise.reject(error); // Reject the error for further handling
  }
);

export default api;
