/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { getColor } from "helpers/utils";
import { IProps } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";
import { useAppContext } from "providers/AppProvider";

interface TinymceEditorProps {
  value?: string;
  onChange?: (value: string) => void;
  options?: IProps["init"];
}

const TinymceEditor = ({
  value,
  onChange,
  options = { height: "50vh" },
}: TinymceEditorProps) => {
  const {
    config: { isDark },
  } = useAppContext();
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const handleEditorFocus = () => {
    const editorContainer = editorRef.current?.editorContainer;
    editorContainer?.classList.add("editor-focused");
  };

  const handleEditorBlur = () => {
    const editorContainer = editorRef.current?.editorContainer;
    editorContainer?.classList.remove("editor-focused");
  };

  const handleEditorStyle = () => {
    // Add the dark mode class dynamically to the body
    if (editorRef.current) {
      editorRef.current.dom.addStyle(
        `.mce-content-body{
          color: ${getColor("emphasis-color")} !important;
          background-color: ${getColor("tinymce-bg")} !important;
        }
        `
      );
    }
    const body = document.body;
    if (isDark) {
      body.classList.add("dark");
    } else {
      body.classList.remove("dark");
    }
  };

  useEffect(() => {
    handleEditorStyle();
  }, [isDark]);

  return (
    <Editor
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      onFocus={handleEditorFocus}
      onBlur={handleEditorBlur}
      // @ts-ignore
      onInit={(evt, editor) => (editorRef.current = editor)}
      value={value}
      onEditorChange={onChange}
      init={{
        skin: "oxide", // Default skin for light mode
        content_css: "dark", // Apply TinyMCE's dark content CSS
        menubar: false,
        forced_root_block: ".",
        statusbar: true, // Ensure status bar is visible
        plugins: ["link", "image", "lists", "table", "media"],
        toolbar: [
          { name: "history", items: ["undo", "redo"] },
          { name: "formatting", items: ["bold", "italic", "underline"] },
          {
            name: "alignment",
            items: ["alignleft", "aligncenter", "alignright"],
          },
          { name: "list", items: ["numlist", "bullist"] },
          { name: "link", items: ["link"] },
        ],
        content_style: `
          body {
            color: ${getColor("emphasis-color")};
            background-color: ${getColor("tinymce-bg")};
          }
          .tox-statusbar {
            color: ${getColor("emphasis-color")};
            background-color: ${getColor("tinymce-bg")};
          }
        `,
        ...options,
      }}
    />
  );
};

export default TinymceEditor;
