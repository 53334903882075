import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./CustomCascader.css";
import "react-loading-skeleton/dist/skeleton.css";

interface Category {
  id: string;
  parent_id: string;
  name: string;
}

interface CustomCascaderProps {
  fetchCategories: (
    parentId: string | null,
    level: number
  ) => Promise<Category[]>;
  onCategorySelected: (selectedCategory: {
    level1: Category | null;
    level2: Category | null;
    level3: Category | null;
  }) => void;
  initialSelection?: {
    level1: Category | null;
    level2: Category | null;
    level3: Category | null;
  };
}

const CustomCascader: React.FC<CustomCascaderProps> = ({
  fetchCategories,
  onCategorySelected,
  initialSelection,
}) => {
  const { t } = useTranslation();

  const [selectedCategoryPath, setSelectedCategoryPath] = useState<string[]>(
    []
  );
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<{
    level1: Category | null;
    level2: Category | null;
    level3: Category | null;
  }>({ level1: null, level2: null, level3: null });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [levelOneCategories, setLevelOneCategories] = useState<Category[]>([]);
  const [levelTwoCategories, setLevelTwoCategories] = useState<Category[]>([]);
  const [levelThreeCategories, setLevelThreeCategories] = useState<Category[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState<string[]>(["", "", ""]);
  const [loadingLevels, setLoadingLevels] = useState({
    level1: false,
    level2: false,
    level3: false,
  });
  const [currentLevel, setCurrentLevel] = useState(1);

  useEffect(() => {
    const fetchAndPopulateLevelsSequentially = async () => {
      if (initialSelection) {
        try {
          if (initialSelection.level1) {
            const level1Categories = await fetchCategories(null, 1);
            setLevelOneCategories(level1Categories);

            if (initialSelection.level2) {
              const level2Categories = await fetchCategories(
                initialSelection.level1.id,
                2
              );
              setLevelTwoCategories(level2Categories);

              if (initialSelection.level3) {
                const level3Categories = await fetchCategories(
                  initialSelection.level2.id,
                  3
                );
                setLevelThreeCategories(level3Categories);
              }
            }
          } else {
            const level1Categories = await fetchCategories(null, 1);
            setLevelOneCategories(level1Categories);
          }

          const newSelectedPath = [
            initialSelection.level1?.name || "",
            initialSelection.level2?.name || "",
            initialSelection.level3?.name || "",
          ].filter((name) => name);

          const newSelectedIds = [
            initialSelection.level1?.id || "",
            initialSelection.level2?.id || "",
            initialSelection.level3?.id || "",
          ].filter((id) => id);

          setSelectedCategoryPath(newSelectedPath);
          setSelectedCategoryIds(newSelectedIds);
          setSelectedCategories(initialSelection);
          onCategorySelected(initialSelection);
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      } else {
        const level1Categories = await fetchCategories(null, 1);
        setLevelOneCategories(level1Categories);
      }
    };

    fetchAndPopulateLevelsSequentially();
  }, [initialSelection]);

  const fetchCategoriesByParentId = async (
    parentId: string | null,
    level: number
  ): Promise<Category[]> => {
    setLoadingLevels((prev) => ({ ...prev, [`level${level}`]: true }));
    try {
      const categories = await fetchCategories(parentId, level);
      if (level === 1) {
        setLevelOneCategories(categories);
      } else if (level === 2) {
        setLevelTwoCategories(categories);
      } else if (level === 3) {
        setLevelThreeCategories(categories);
      }
      return categories;
    } catch (error) {
      console.error("Error fetching categories:", error);
      return [];
    } finally {
      setLoadingLevels((prev) => ({ ...prev, [`level${level}`]: false }));
    }
  };

  const handleCategoryClick = (category: Category, level: number) => {
    const newSelectedPath = [...selectedCategoryPath];
    const newSelectedIds = [...selectedCategoryIds];

    newSelectedPath[level - 1] = category.name;
    newSelectedIds[level - 1] = category.id;

    setSelectedCategoryPath(newSelectedPath);
    setSelectedCategoryIds(newSelectedIds);

    const newSelectedCategories = { ...selectedCategories };
    newSelectedCategories[`level${level}` as "level1" | "level2" | "level3"] =
      category;
    setSelectedCategories(newSelectedCategories);

    if (level === 1) {
      setCurrentLevel(2);
      fetchCategoriesByParentId(category.id, 2);
      setLevelTwoCategories([]);
      setLevelThreeCategories([]);
    } else if (level === 2) {
      setCurrentLevel(3);
      fetchCategoriesByParentId(category.id, 3);
      setLevelThreeCategories([]);
    } else if (level === 3) {
      setDropdownOpen(false);
      onCategorySelected(newSelectedCategories);
    }
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<any>,
    level: number
  ) => {
    const input = e.target as HTMLInputElement;
    const newSearchTerms = [...searchTerm];
    newSearchTerms[level - 1] = input.value;
    setSearchTerm(newSearchTerms);
  };

  const handleDropdownClose = () => {
    if (selectedCategoryPath.length < 3) {
      setSelectedCategoryPath([]);
      setSelectedCategoryIds([]);
      setSearchTerm(["", "", ""]);
      setCurrentLevel(1);
      setSelectedCategories({ level1: null, level2: null, level3: null });
    }
    setDropdownOpen(false);
  };

  const filterCategories = (categories: Category[], searchTerm: string) => {
    return categories.filter((cat) =>
      cat.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  return (
    <div className="mb-5 custom-cascader">
      <InputGroup onClick={() => setDropdownOpen(true)}>
        <Form.Control
          placeholder={t("selectCategory")}
          value={selectedCategoryPath.join(" > ")}
          readOnly
        />
      </InputGroup>
      {dropdownOpen && (
        <>
          <div className="cascader-backdrop" onClick={handleDropdownClose} />
          <div className="cascader-dropdown">
            <div className="cascader-menus">
              <div className="cascader-menu">
                <Form.Control
                  type="text"
                  placeholder={t("searchPlaceholder")}
                  value={searchTerm[0]}
                  onChange={(e) => handleSearchChange(e, 1)}
                />
                <ul>
                  {loadingLevels.level1
                    ? Array(5)
                        .fill(null)
                        .map((_, index) => (
                          <li key={`skeleton-${index}`}>
                            <Skeleton height={20} />
                          </li>
                        ))
                    : filterCategories(levelOneCategories, searchTerm[0]).map(
                        (cat) => (
                          <li
                            key={cat.id}
                            onClick={() => handleCategoryClick(cat, 1)}
                            className={
                              selectedCategoryIds[0] === cat.id
                                ? "selected"
                                : ""
                            }
                          >
                            {cat.name} <FontAwesomeIcon icon={faAngleRight} />
                          </li>
                        )
                      )}
                </ul>
              </div>
              {currentLevel >= 2 && (
                <div className="cascader-menu">
                  <Form.Control
                    type="text"
                    placeholder={t("searchPlaceholder")}
                    value={searchTerm[1]}
                    onChange={(e) => handleSearchChange(e, 2)}
                  />
                  <ul>
                    {loadingLevels.level2
                      ? Array(5)
                          .fill(null)
                          .map((_, index) => (
                            <li key={`skeleton-${index}`}>
                              <Skeleton height={20} />
                            </li>
                          ))
                      : filterCategories(levelTwoCategories, searchTerm[1]).map(
                          (cat) => (
                            <li
                              key={cat.id}
                              onClick={() => handleCategoryClick(cat, 2)}
                              className={
                                selectedCategoryIds[1] === cat.id
                                  ? "selected"
                                  : ""
                              }
                            >
                              {cat.name} <FontAwesomeIcon icon={faAngleRight} />
                            </li>
                          )
                        )}
                  </ul>
                </div>
              )}
              {currentLevel >= 3 && (
                <div className="cascader-menu">
                  <Form.Control
                    type="text"
                    placeholder={t("searchPlaceholder")}
                    value={searchTerm[2]}
                    onChange={(e) => handleSearchChange(e, 3)}
                  />
                  <ul>
                    {loadingLevels.level3
                      ? Array(5)
                          .fill(null)
                          .map((_, index) => (
                            <li key={`skeleton-${index}`}>
                              <Skeleton height={20} />
                            </li>
                          ))
                      : filterCategories(
                          levelThreeCategories,
                          searchTerm[2]
                        ).map((cat) => (
                          <li
                            key={cat.id}
                            onClick={() => handleCategoryClick(cat, 3)}
                            className={
                              selectedCategoryIds[2] === cat.id
                                ? "selected"
                                : ""
                            }
                          >
                            {cat.name}
                          </li>
                        ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomCascader;
