import React, { useState, useCallback, useEffect, useRef } from "react";
import Button from "components/base/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "components/custom/CustomDropzone";
import TinymceEditor from "components/base/TinymceEditor";
import VariantFormCard from "components/cards/VariantFormCard";
import ProductVariantsTable from "components/custom/ProductVariantsTable";
import { Col, Form, Row, Card, Toast, ToastContainer } from "react-bootstrap";
import CustomCascader from "components/custom/CustomCascader";
import ProductTabs from "components/tabs/ProductTabs";
import api from "api/api";
import { useTranslation } from "react-i18next";
import productAttributes from "config/productAttributes";
import { Typeahead } from "react-bootstrap-typeahead";
import { FileAttachment } from "components/custom/types";
interface Option {
  option_id: string;
  attribute_id?: string;
  value?: string;
  swatch?: string;
}
interface OptionsGetter {
  method: string;
  url: string;
  param: {
    key: string;
    value: string;
  };
  token: string;
  minlength: number;
  debounceTime: number;
}
interface Attribute {
  attribute_id: string;
  attribute_code: string;
  frontend_label: string;
  is_required: string;
  input_type: string;
  options_getter?: OptionsGetter;
  options?: Option[];
}
interface AttributeGroup {
  name: string;
  icon?: string;
  display?: string;
  attributes: Attribute[];
}
interface CategorySelection {
  level1: { id: string; parent_id: string; name: string } | null;
  level2: { id: string; parent_id: string; name: string } | null;
  level3: { id: string; parent_id: string; name: string } | null;
}
const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;
  return function (this: void, ...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};
const fetchOptions = async (optionsGetter: OptionsGetter, query: string) => {
  if (query.length < optionsGetter.minlength) {
    return [];
  }
  try {
    const response = await api.get(
      `${optionsGetter.url}?${optionsGetter.param.key}=${query}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching options:", error);
    return [];
  }
};
const LOCAL_STORAGE_KEY = "addProductFormValues";
const AddProduct: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<{ [key: string]: any }>(() => {
    const savedValues = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedValues ? JSON.parse(savedValues) : {};
  });
  const [characteristicsAttributes, setCharacteristicsAttributes] = useState<
    Attribute[]
  >([]);
  const [dynamicOptions, setDynamicOptions] = useState<{
    [key: string]: Option[];
  }>({});
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);
  const [initialCategorySelection, setInitialCategorySelection] =
    useState<CategorySelection>({
      level1: null,
      level2: null,
      level3: null,
    });
  //variations states
  const [variationAttributes, setVariationAttributes] = useState<Attribute[]>(
    []
  );
  const [isLoadingAttributes, setIsLoadingAttributes] =
    useState<boolean>(false);
  const [generatedVariants, setGeneratedVariants] = useState<any[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<Attribute[]>([]);
  const productVariantsRef = useRef<HTMLDivElement>(null);
  //Toast states
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState<"success" | "danger">(
    "danger"
  );
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const validateForm = () => {
    const invalidFields: string[] = [];

    // Validate fields in common attribute groups
    productAttributes.CommonAttributes.AttributeGroups.forEach((group) => {
      if (typeof group.attributes !== "string") {
        const excludedAttributes = ["category_ids", "sku"];
        group.attributes.forEach((attribute) => {
          if (
            attribute.is_required === "1" &&
            !formValues[attribute.attribute_code] &&
            !excludedAttributes.includes(attribute.attribute_code)
          ) {
            invalidFields.push(attribute.attribute_code);
          }
        });
      }
    });

    // Validate fields in ProductTabs
    allInTabGroups.forEach((group) => {
      if (typeof group.attributes !== "string") {
        group.attributes.forEach((attribute) => {
          if (
            attribute.is_required === "1" &&
            !formValues[attribute.attribute_code]
          ) {
            invalidFields.push(attribute.attribute_code);
          }
        });
      }
    });
    console.log("invalidFields", invalidFields);
    return invalidFields;
  };

  const handleInputChange = useCallback(
    debounce(async (query: string, attribute: Attribute) => {
      if (attribute.options_getter) {
        setIsLoadingOptions(true);
        const options = await fetchOptions(attribute.options_getter, query);
        setDynamicOptions((prev) => ({
          ...prev,
          [attribute.attribute_code]: options,
        }));
        setIsLoadingOptions(false);
      }
    }, 500),
    []
  );
  const fetchCategories = async (parentId: string | null, level: number) => {
    try {
      const rootCategoryId = parentId || "2";
      const response = await api.get(
        `/categories?rootCategoryId=${rootCategoryId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching categories:", error);
      return [];
    }
  };
  const fetchFeatures = async (categoryId: string) => {
    try {
      setIsLoadingAttributes(true);
      const response = await api.get(`/getfeatures?categoryId=${categoryId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      setCharacteristicsAttributes(response.data);

      const existingVariationAttributes =
        productAttributes.VariationAttributes.filter((variationAttr) =>
          response.data.some(
            (fetchedAttr: Attribute) =>
              fetchedAttr.attribute_code === variationAttr.attribute_code
          )
        ).map((variationAttr) => {
          const matchedAttr = response.data.find(
            (fetchedAttr: Attribute) =>
              fetchedAttr.attribute_code === variationAttr.attribute_code
          );
          if (matchedAttr) {
            return {
              ...variationAttr,
              options: matchedAttr.options,
            };
          }
          return variationAttr;
        });

      setVariationAttributes(existingVariationAttributes);
      setIsLoadingAttributes(false);
    } catch (error) {
      console.error("Error fetching features:", error);
      setIsLoadingAttributes(false);
    }
  };
  const handleCategorySelection = (selectedCategory: CategorySelection) => {
    handleChange("category", selectedCategory);
    if (selectedCategory.level3) {
      fetchFeatures(selectedCategory.level3.id);
    }
  };
  const handleClear = () => {
    // Clear form values and set to an empty state
    setFormValues({});

    // Clear local storage value for the form
    localStorage.removeItem(LOCAL_STORAGE_KEY);

    // Clear generated variants and other relevant states
    setGeneratedVariants([]);
    setSelectedAttributes([]);
    setInitialCategorySelection({
      level1: null,
      level2: null,
      level3: null,
    });

    // Reset gallery images (drag and drop files)
    setFormValues((prevValues) => ({
      ...prevValues,
      gallery: [],
    }));

    // Reset brand field
    setFormValues((prevValues) => ({
      ...prevValues,
      brand: [],
    }));
    // Refresh the page to ensure full reset
    //window.location.reload();
  };

  const handleSaveDraft = async () => {
    setLoading(true); // Set loading state to true
    console.log("formValues", formValues);
    try {
      // Your logic to save the draft here
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(formValues));
    } catch (error) {
      console.error("Error saving draft:", error);
    } finally {
      setLoading(false); // Set loading state to false when done
    }
  };
  const handlePublishProduct = async () => {
    // Validate the form
    const missingFields = validateForm();

    if (missingFields.length > 0) {
      // Set the toast message and show the toast
      setToastMessage("Please fill out all required fields.");
      setToastVariant("danger");
      setShowToast(true);

      // Highlight invalid fields
      setInvalidFields(missingFields);
      return;
    }

    // Clear invalid fields
    setInvalidFields([]);

    // Set loading state
    setLoading(true);

    // Clone formValues to avoid directly mutating state
    const payload = { ...formValues };
    // Remove specified keys if they exist
    delete payload.selectedAttributeOptions_0;
    delete payload.selectedAttributeOptions_1;
    delete payload.variantAttribute_0;
    delete payload.variantAttribute_1;
    if (!payload.gallery) {
      payload.gallery = [];
    }
    try {
      const response = await api.post("/product/create", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      // Show success toast
      setToastMessage("Product published successfully.");
      setToastVariant("success");
      setShowToast(true);

      // Handle success
      console.log("Success:", response.data);
      handleClear();
    } catch (error) {
      console.error("Error publishing product:", error);
      setToastMessage("Error publishing product.");
      setToastVariant("danger");
      setShowToast(true);
    } finally {
      // Reset loading state
      setLoading(false);
    }
  };

  const handleChange = (attribute_code: string, value: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [attribute_code]: value,
    }));
  };
  const bottomRef = useRef<HTMLDivElement>(null); // Create a ref for the bottom element
  const handleCreateVariants = (
    variants: any[],
    selectedAttributes: Attribute[]
  ) => {
    const baseImage = formValues.gallery?.find(
      (file: any) => file.roles?.Base === true
    );
    const defaultPrice = formValues.price || "";
    const defaultQuantity = 0;

    const variantsWithImage = variants.map((variant, index) => {
      const id = `${variant[selectedAttributes[0].attribute_code]?.option_id || ""}-${index}`;
      return {
        ...variant,
        id,
        image: baseImage,
        price: defaultPrice,
        quantity: defaultQuantity,
      };
    });

    setGeneratedVariants(variantsWithImage);
    setSelectedAttributes(selectedAttributes);

    setFormValues((prevValues) => ({
      ...prevValues,
      variantsData: variantsWithImage,
      selectedAttributes: selectedAttributes,
    }));

    // Smooth scroll to the bottom of the table
    setTimeout(() => {
      const element = productVariantsRef.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const elementHeight = rect.height;

        // Scroll to the element's bottom
        window.scrollTo({
          top: scrollTop + rect.top + elementHeight,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  const handleRemoveVariant = (index: number) => {
    const updatedVariants = [...generatedVariants];
    updatedVariants.splice(index, 1);
    setGeneratedVariants(updatedVariants);
    const updatedFormValues = { ...formValues };
    delete updatedFormValues[`selectedAttributeOptions_${index}`];
    delete updatedFormValues[`variantAttribute_${index}`];
    if (index === 0 && formValues.variantAttribute_1) {
      updatedFormValues.variantAttribute_0 = formValues.variantAttribute_1;
      delete updatedFormValues.variantAttribute_1;
      updatedFormValues.selectedAttributeOptions_0 =
        formValues.selectedAttributeOptions_1;
      delete updatedFormValues.selectedAttributeOptions_1;
    } else if (index === 1) {
      delete updatedFormValues.variantAttribute_1;
      delete updatedFormValues.selectedAttributeOptions_1;
    }
    setFormValues({
      ...updatedFormValues,
      variantsData: updatedVariants, // Save updated variantsData
    });
  };
  // ProductVariantsTable.tsx
  const createCompositeKey = (variant: any, attributes: Attribute[]) => {
    return attributes
      .map((attr) => variant[attr.attribute_code]?.option_id || "")
      .join("-");
  };
  const handleDeleteVariant = (compositeKey: string) => {
    setGeneratedVariants((prevVariants) =>
      prevVariants.filter((variant) => {
        const variantKey = createCompositeKey(variant, selectedAttributes);
        return variantKey !== compositeKey;
      })
    );
  };
  const handleUpdateVariantImage = (
    imagedAttributeCode: string,
    imagedAttributeValue: string,
    newImage: FileAttachment
  ) => {
    setGeneratedVariants((prevVariants) => {
      const updatedVariants = prevVariants.map((variant) => {
        if (variant[imagedAttributeCode]?.value === imagedAttributeValue) {
          return {
            ...variant,
            image: newImage,
          };
        }
        return variant;
      });
      setFormValues((prevValues) => ({
        ...prevValues,
        variantsData: updatedVariants, // Save updated variantsData
      }));
      return updatedVariants;
    });
  };
  const handleUpdateVariant = (updatedVariants: any[]) => {
    setGeneratedVariants(updatedVariants);

    setFormValues((prevValues) => ({
      ...prevValues,
      variantsData: updatedVariants,
    }));
  };
  const handleFileDrop = async (files: any[]) => {
    const updatedGallery = [...(formValues["gallery"] || [])];
    files.forEach((file) => {
      updatedGallery.push(file);
      setFormValues((prevValues) => ({
        ...prevValues,
        gallery: updatedGallery,
      }));
    });
  };
  const handleFileRemove = (fileName: string) => {
    setFormValues((prevValues) => {
      const updatedGallery = prevValues["gallery"].filter(
        (file: FileAttachment) => file.name !== fileName
      );
      return {
        ...prevValues,
        gallery: updatedGallery,
      };
    });
  };
  const handleFileUpdate = (
    index: number,
    updatedData: Partial<FileAttachment>
  ) => {
    setFormValues((prevValues) => {
      const updatedGallery = [...(prevValues["gallery"] || [])];

      // Clear the roles from all other images
      if (updatedData.roles) {
        Object.keys(updatedData.roles).forEach((role) => {
          if (updatedData.roles![role]) {
            updatedGallery.forEach((file, i) => {
              if (i !== index && file.roles && file.roles![role]) {
                file.roles![role] = false;
              }
            });
          }
        });
      }

      updatedGallery[index] = {
        ...updatedGallery[index],
        ...updatedData,
      };

      return {
        ...prevValues,
        gallery: updatedGallery,
      };
    });
  };
  const handleFileIndexChange = (fromIndex: number, toIndex: number) => {
    setFormValues((prevValues) => {
      const updatedGallery = [...(prevValues["gallery"] || [])];
      const [movedFile] = updatedGallery.splice(fromIndex, 1);
      updatedGallery.splice(toIndex, 0, movedFile);

      return {
        ...prevValues,
        gallery: updatedGallery,
      };
    });
  };
  useEffect(() => {
    const savedValues = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedValues) {
      const parsedValues = JSON.parse(savedValues);
      setFormValues(parsedValues);
      if (parsedValues.category) {
        setInitialCategorySelection(parsedValues.category as CategorySelection);
      }
      if (parsedValues.variantsData) {
        setGeneratedVariants(parsedValues.variantsData);
      }
      if (parsedValues.selectedAttributes) {
        setSelectedAttributes(parsedValues.selectedAttributes);
      }
    }
  }, []);
  useEffect(() => {
    if (generatedVariants.length > 0 && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [generatedVariants]);

  useEffect(() => {
    // Run handleSaveDraft every 5 seconds
    /*const intervalId = setInterval(() => {
      handleSaveDraft();
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
    */
  }, []);
  const commonAttributeGroups =
    productAttributes.CommonAttributes.AttributeGroups.filter(
      (group) => !group.display || group.display !== "inTab"
    );

  const inTabAttributeGroups =
    productAttributes.CommonAttributes.AttributeGroups.filter(
      (group) => group.display && group.display === "inTab"
    );

  const allInTabGroups: AttributeGroup[] = [...inTabAttributeGroups];
  const characteristicsGroup = allInTabGroups.find(
    (group) => group.name === "Caractéristiques"
  );

  if (characteristicsGroup) {
    if (Array.isArray(characteristicsGroup.attributes)) {
      characteristicsGroup.attributes = characteristicsAttributes;
    }
  } else {
    allInTabGroups.push({
      name: "Caractéristiques",
      icon: "sliders",
      attributes: characteristicsAttributes,
    });
  }
  return (
    <div>
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ position: "fixed", top: "1rem", right: "1rem", zIndex: 9999 }}
      >
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          bg={toastVariant === "success" ? "success" : "danger"}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <form className="mb-12">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2
              className="mb-2"
              style={{ textAlign: isArabic ? "right" : "left" }}
            >
              {t("addProductTitle")}
            </h2>
            <h5
              className="text-body-tertiary fw-semibold"
              style={{ textAlign: isArabic ? "right" : "left" }}
            >
              {t("addProductSubtitle")}
            </h5>
          </div>
          <div className="d-flex flex-wrap gap-2">
            <Button
              variant="phoenix-secondary"
              type="button"
              onClick={handleClear}
            >
              {t("clear")}
            </Button>
            <Button
              variant="phoenix-primary"
              type="button"
              onClick={handleSaveDraft}
              style={{
                opacity: loading ? 0.5 : 1, // Apply transparency when loading
              }}
            >
              <FontAwesomeIcon
                icon={faFloppyDisk}
                style={{ marginRight: "8px" }} // Adjust the margin to create space
              />
              {t("saveDraft")}
            </Button>

            <Button
              variant="primary"
              type="button"
              onClick={handlePublishProduct}
            >
              {t("publishProduct")}
            </Button>
          </div>
        </div>
        <Row className="g-5">
          <Col xs={12} xl={8}>
            {commonAttributeGroups.map(
              (group) =>
                typeof group.attributes !== "string" &&
                group.attributes.map((attribute: Attribute) => {
                  const inputValue = formValues[attribute.attribute_code] || "";

                  const options = attribute.options_getter
                    ? [
                        { option_id: "0", value: "", swatch: null },
                        ...(dynamicOptions[attribute.attribute_code] || []),
                      ]
                    : [
                        { option_id: "0", value: "", swatch: null },
                        ...((Array.isArray(attribute.options)
                          ? attribute.options
                          : []) as Option[]),
                      ];

                  const savedOptions = Array.isArray(
                    formValues[attribute.attribute_code]
                  )
                    ? formValues[attribute.attribute_code]
                    : [];
                  const combinedOptions = [
                    ...savedOptions,
                    ...options.filter(
                      (opt) =>
                        !savedOptions.find(
                          (savedOpt: { option_id: string }) =>
                            savedOpt.option_id === opt.option_id
                        )
                    ),
                  ];

                  const selectedOptions =
                    savedOptions.length > 0 ? savedOptions : [];

                  switch (attribute.input_type) {
                    case "text":
                    case "price":
                    case "weight":
                      return (
                        <Form.Group
                          key={attribute.attribute_id}
                          className="mb-3"
                        >
                          <Form.Label
                            style={{ textAlign: isArabic ? "right" : "left" }}
                          >
                            {t(attribute.frontend_label)}{" "}
                            {attribute.is_required === "1" && (
                              <span className="text-danger">*</span>
                            )}
                          </Form.Label>
                          <Form.Control
                            type={
                              attribute.input_type === "text"
                                ? "text"
                                : "number"
                            }
                            placeholder={t(attribute.frontend_label)}
                            required={attribute.is_required === "1"}
                            value={inputValue}
                            onChange={(e) =>
                              handleChange(
                                attribute.attribute_code,
                                e.target.value
                              )
                            }
                            className={
                              invalidFields.includes(attribute.attribute_code)
                                ? "is-invalid"
                                : ""
                            }
                          />
                        </Form.Group>
                      );
                    case "textarea":
                      return (
                        <Form.Group
                          key={attribute.attribute_id}
                          className="mb-3"
                        >
                          <Form.Label
                            style={{ textAlign: isArabic ? "right" : "left" }}
                          >
                            {t(attribute.frontend_label)}{" "}
                            {attribute.is_required === "1" && (
                              <span className="text-danger">*</span>
                            )}
                          </Form.Label>
                          <TinymceEditor
                            options={{
                              height: "15rem",
                              placeholder: t(attribute.frontend_label),
                            }}
                            value={inputValue}
                            onChange={(content) =>
                              handleChange(attribute.attribute_code, content)
                            }
                          />
                        </Form.Group>
                      );
                    case "gallery":
                      return (
                        <Form.Group
                          key={attribute.attribute_id}
                          className="mb-3"
                        >
                          <Form.Label
                            style={{ textAlign: isArabic ? "right" : "left" }}
                          >
                            {t(attribute.frontend_label)}{" "}
                            {attribute.is_required === "1" && (
                              <span className="text-danger">*</span>
                            )}
                          </Form.Label>
                          <Dropzone
                            accept={{
                              "image/*": [".png", ".gif", ".jpeg", ".jpg"],
                            }}
                            onDrop={handleFileDrop}
                            defaultFiles={(formValues["gallery"] || []).map(
                              (fileData: FileAttachment) => ({
                                name: fileData.name,
                                preview: fileData.preview,
                                size: fileData.size,
                                resolution: fileData.resolution,
                                altText: fileData.altText,
                                hideFromProductPage:
                                  fileData.hideFromProductPage,
                                roles: fileData.roles,
                              })
                            )}
                            onFileRemove={handleFileRemove}
                            onFileUpdate={handleFileUpdate}
                            onFileIndexChange={handleFileIndexChange}
                          />
                        </Form.Group>
                      );
                    case "cascader":
                      return (
                        <Form.Group
                          key={attribute.attribute_id}
                          className="mb-5"
                        >
                          <Form.Label
                            style={{ textAlign: isArabic ? "right" : "left" }}
                          >
                            {t(attribute.frontend_label)}{" "}
                            {attribute.is_required === "1" && (
                              <span className="text-danger">*</span>
                            )}
                          </Form.Label>
                          <CustomCascader
                            fetchCategories={fetchCategories}
                            onCategorySelected={handleCategorySelection}
                            initialSelection={initialCategorySelection}
                          />
                        </Form.Group>
                      );
                    case "select":
                    case "multiselect":
                      return (
                        <Form.Group
                          key={attribute.attribute_id}
                          className="mb-3"
                        >
                          <Form.Label
                            style={{ textAlign: isArabic ? "right" : "left" }}
                          >
                            {t(attribute.frontend_label)}{" "}
                            {attribute.is_required === "1" && (
                              <span className="text-danger">*</span>
                            )}
                          </Form.Label>
                          <Typeahead
                            id={attribute.attribute_id}
                            className={
                              invalidFields.includes(attribute.attribute_code)
                                ? "is-invalid"
                                : ""
                            }
                            labelKey="value"
                            multiple={attribute.input_type === "multiselect"}
                            options={combinedOptions}
                            isLoading={isLoadingOptions}
                            placeholder={t(attribute.frontend_label)}
                            onInputChange={(query) => {
                              if (attribute.options_getter) {
                                handleInputChange(query, attribute);
                              }
                            }}
                            {...(selectedOptions.length > 0 &&
                            Array.isArray(selectedOptions)
                              ? { selected: selectedOptions }
                              : [{}])}
                            onChange={(selected) =>
                              handleChange(attribute.attribute_code, selected)
                            }
                            renderMenuItemChildren={(option) => {
                              const opt = option as Option;
                              const displayValue = opt.value || "";
                              return (
                                <div className="d-flex align-items-center">
                                  {opt.swatch && (
                                    <div
                                      style={{
                                        backgroundColor: opt.swatch,
                                        width: "16px",
                                        height: "16px",
                                        marginRight: "8px",
                                        border: "1px solid #ccc",
                                      }}
                                    />
                                  )}
                                  <span>{t(displayValue)}</span>
                                </div>
                              );
                            }}
                          />
                        </Form.Group>
                      );
                    default:
                      return null;
                  }
                })
            )}

            <ProductTabs
              AttributeGroups={allInTabGroups as unknown as AttributeGroup[]}
              formValues={formValues}
              handleChange={handleChange}
              invalidFields={invalidFields}
            />
          </Col>

          <Col xs={12} xl={4}>
            <Row className="g-2">
              <Col xs={12} xl={12}>
                <VariantFormCard
                  variationAttributes={variationAttributes}
                  handleChange={handleChange}
                  formValues={formValues}
                  onCreateVariants={handleCreateVariants}
                  onRemoveVariant={handleRemoveVariant}
                  isLoadingAttributes={isLoadingAttributes}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
      <Row className="g-2">
        <Col xs={12} xl={12}>
          {generatedVariants.length > 0 && (
            <div ref={productVariantsRef}>
              <ProductVariantsTable
                variantsData={generatedVariants}
                selectedAttributes={selectedAttributes}
                onDeleteVariant={handleDeleteVariant}
                onUpdateVariant={handleUpdateVariant}
                formValues={formValues}
                onUpdateVariantImage={handleUpdateVariantImage}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AddProduct;
