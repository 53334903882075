import SignInForm from "components/modules/auth/SignInForm";
import AuthSimpleLayout from "layouts/AuthSimpleLayout";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const CreativeFooter = () => {
  const { t, i18n } = useTranslation();

  const languageOptions = [
    {
      value: "en",
      label: (
        <span>
          <img
            src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
            alt="English"
            style={{ width: "20px", marginRight: "8px" }}
          />
          English
        </span>
      ),
    },
    {
      value: "fr",
      label: (
        <span>
          <img
            src="https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
            alt="French"
            style={{ width: "20px", marginRight: "8px" }}
          />
          Français
        </span>
      ),
    },
    {
      value: "ar",
      label: (
        <span>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg"
            alt="Arabic"
            style={{ width: "20px", marginRight: "8px" }}
          />
          العربية
        </span>
      ),
    },
  ];

  const handleLanguageChange = (selectedOption: any) => {
    i18n.changeLanguage(selectedOption.value);
  };

  return (
    <footer className="bg-light py-4 border-top">
      <div className="container">
        <Row className="align-items-center">
          {/* Left Section: Brand and Rights */}
          <Col
            xs={12}
            md="6"
            className="text-center text-md-start mb-3 mb-md-0"
          >
            <p className="mb-0">
              &copy; {new Date().getFullYear()}{" "}
              <a
                href="https://www.wamia.tn"
                target="_blank"
                rel="noreferrer"
                className="text-primary fw-semibold"
              >
                Wamia
              </a>
              . {t("allRightsReserved")}
            </p>
          </Col>

          {/* Right Section: Language Switcher with Flags */}
          <Col xs={12} md="6" className="text-center text-md-end">
            <div className="language-selector">
              <Select
                options={languageOptions}
                onChange={handleLanguageChange}
                defaultValue={languageOptions.find(
                  (option) => option.value === i18n.language
                )}
                isSearchable={false}
                components={{ IndicatorSeparator: () => null }}
                styles={{
                  control: (base) => ({
                    ...base,
                    width: 150,
                  }),
                  singleValue: (base) => ({
                    ...base,
                    display: "flex",
                    alignItems: "center",
                  }),
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

const SignIn = () => {
  return (
    <AuthSimpleLayout>
      <div className="signin-container">
        <SignInForm layout="simple" />
        <CreativeFooter />
      </div>
    </AuthSimpleLayout>
  );
};

export default SignIn;
