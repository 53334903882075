import React, { useState } from "react";
import Button from "components/base/Button";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import api from "api/api";
import { useAuth } from "providers/AuthProvider";
import { useTranslation } from "react-i18next";

const SignUpForm = ({ layout }: { layout: "simple" | "card" | "split" }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    shopTitle: "",
    contactNumber: "",
    description: "",
    firstname: "",
    lastname: "",
    company: "",
    street: "",
    city: "",
    postcode: "",
    region: "Tunis",
    region_id: 11,
    region_code: "TU",
    vat_id: "",
  });

  const [errors, setErrors] = useState<string[]>([]);
  const { setSeller, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    if (name === "shopTitle") {
      setFormData({
        ...formData,
        shopTitle: value,
        company: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const requestData = {
      email: formData.email,
      password: formData.password,
      shopTitle: formData.shopTitle,
      contactNumber: formData.contactNumber,
      description: formData.description,
      billingAddress: {
        firstname: formData.firstname,
        lastname: formData.lastname,
        company: formData.company,
        street: [formData.street],
        city: formData.city,
        postcode: formData.postcode,
        country_id: "TN",
        region: {
          region: formData.region,
          region_id: formData.region_id,
          region_code: formData.region_code,
        },
        vat_id: formData.vat_id,
      },
    };

    try {
      const response = await api.post("/seller/register", requestData);
      const { seller, token } = response.data[0];
      localStorage.setItem("authToken", token);
      localStorage.setItem("seller", JSON.stringify(seller));
      setSeller(seller);
      setIsAuthenticated(true);
      navigate("/");
    } catch (error: any) {
      const formattedMessage = error.response.data.message.replace(
        /%(\d+)/g,
        (match: string, p1: string) => {
          return error.response.data.parameters[parseInt(p1, 10) - 1] || match;
        }
      );
      setErrors([formattedMessage]);
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">{t("signUp")}</h3>
        <p className="text-body-tertiary">{t("createYourAccountToday")}</p>
      </div>
      {errors.length > 0 && (
        <div className="alert alert-danger">
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="position-relative mt-4">
        <hr className="bg-body-secondary" />
        <div className="divider-content-center">
          {t("mainContactInformations")}
        </div>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="firstname">{t("firstName")}</Form.Label>
          <Form.Control
            id="firstname"
            name="firstname"
            type="text"
            placeholder={t("firstName")}
            value={formData.firstname}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="lastname">{t("lastName")}</Form.Label>
          <Form.Control
            id="lastname"
            name="lastname"
            type="text"
            placeholder={t("lastName")}
            value={formData.lastname}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">{t("emailAddress")}</Form.Label>
          <Form.Control
            id="email"
            name="email"
            type="email"
            placeholder={t("emailPlaceholder")}
            value={formData.email}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="contactNumber">{t("contactNumber")}</Form.Label>
          <Form.Control
            id="contactNumber"
            name="contactNumber"
            type="text"
            placeholder={t("contactNumber")}
            value={formData.contactNumber}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Row className="g-3 mb-3">
          <Col sm={layout === "card" ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password">{t("password")}</Form.Label>
              <Form.Control
                id="password"
                name="password"
                type="password"
                placeholder={t("password")}
                value={formData.password}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col sm={layout === "card" ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="confirmPassword">
                {t("confirmPassword")}
              </Form.Label>
              <Form.Control
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder={t("confirmPassword")}
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="position-relative mt-4">
          <hr className="bg-body-secondary" />
          <div className="divider-content-center">{t("storeInformations")}</div>
        </div>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="shopTitle">{t("shopTitle")}</Form.Label>
          <Form.Control
            id="shopTitle"
            name="shopTitle"
            type="text"
            placeholder={t("shopTitle")}
            value={formData.shopTitle}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="company">{t("company")}</Form.Label>
          <Form.Control
            id="company"
            name="company"
            type="text"
            placeholder={t("company")}
            value={formData.company}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="description">{t("description")}</Form.Label>
          <Form.Control
            id="description"
            name="description"
            as="textarea"
            rows={3}
            placeholder={t("description")}
            value={formData.description}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="street">{t("streetAddress")}</Form.Label>
          <Form.Control
            id="street"
            name="street"
            type="text"
            placeholder={t("streetAddress")}
            value={formData.street}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="city">{t("city")}</Form.Label>
          <Form.Control
            id="city"
            name="city"
            type="text"
            placeholder={t("city")}
            value={formData.city}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="postcode">{t("postalCode")}</Form.Label>
          <Form.Control
            id="postcode"
            name="postcode"
            type="text"
            placeholder={t("postalCode")}
            value={formData.postcode}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="region">{t("governorate")}</Form.Label>
          <Form.Select
            id="region"
            name="region"
            value={formData.region}
            onChange={handleInputChange}
          >
            <option value="Ariana">{t("regionOptions.Ariana")}</option>
            <option value="Béja">{t("regionOptions.Béja")}</option>
            <option value="Ben Arous">{t("regionOptions.Ben Arous")}</option>
            <option value="Bizerte">{t("regionOptions.Bizerte")}</option>
            <option value="Gabès">{t("regionOptions.Gabès")}</option>
            <option value="Gafsa">{t("regionOptions.Gafsa")}</option>
            <option value="Jendouba">{t("regionOptions.Jendouba")}</option>
            <option value="Kairouan">{t("regionOptions.Kairouan")}</option>
            <option value="Kasserine">{t("regionOptions.Kasserine")}</option>
            <option value="Kébili">{t("regionOptions.Kébili")}</option>
            <option value="Kef">{t("regionOptions.Kef")}</option>
            <option value="Mahdia">{t("regionOptions.Mahdia")}</option>
            <option value="Manouba">{t("regionOptions.Manouba")}</option>
            <option value="Medenine">{t("regionOptions.Medenine")}</option>
            <option value="Monastir">{t("regionOptions.Monastir")}</option>
            <option value="Nabeul">{t("regionOptions.Nabeul")}</option>
            <option value="Sfax">{t("regionOptions.Sfax")}</option>
            <option value="Sidi Bouzid">
              {t("regionOptions.Sidi Bouzid")}
            </option>
            <option value="Siliana">{t("regionOptions.Siliana")}</option>
            <option value="Sousse">{t("regionOptions.Sousse")}</option>
            <option value="Tataouine">{t("regionOptions.Tataouine")}</option>
            <option value="Tozeur">{t("regionOptions.Tozeur")}</option>
            <option value="Tunis">{t("regionOptions.Tunis")}</option>
            <option value="Zaghouan">{t("regionOptions.Zaghouan")}</option>

            {/* Add all other regions similarly */}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="vat_id">{t("vatId")}</Form.Label>
          <Form.Control
            id="vat_id"
            name="vat_id"
            type="text"
            placeholder={t("vatId")}
            value={formData.vat_id}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Check type="checkbox" className="mb-3">
          <Form.Check.Input
            type="checkbox"
            name="termsService"
            id="termsService"
          />
          <Form.Check.Label
            htmlFor="termsService"
            className="fs-9 text-transform-none"
          >
            {t("termsService")} <Link to="#!">{t("terms")}</Link> {t("and")}{" "}
            <Link to="#!">{t("privacyPolicy")}</Link>
          </Form.Check.Label>
        </Form.Check>
        <Button variant="primary" className="w-100 mb-3" type="submit">
          {t("signUpButton")}
        </Button>
        <div className="text-center">
          <Link
            to={`/pages/authentication/${layout}/sign-in`}
            className="fs-9 fw-bold"
          >
            {t("signInToExistingAccount")}
          </Link>
        </div>
      </Form>
    </>
  );
};

export default SignUpForm;
