import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FileAttachment } from "./types";
import api from "api/api";
import Skeleton from "react-loading-skeleton";
import defaultimage from "assets/img/img-default/Empty state.jpg";
interface VariantImageProps {
  image: FileAttachment;
  onImageUpload: (newImage: FileAttachment) => void;
}

const VariantImage: React.FC<VariantImageProps> = ({
  image,
  onImageUpload,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [localImage, setLocalImage] = useState<FileAttachment | null>(image);
  const [isUploading, setIsUploading] = useState<boolean>(false); // Track upload status
  const [uploadProgress, setUploadProgress] = useState<number>(0); // Track upload progress
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsUploading(true); // Start upload process
      setUploadProgress(0); // Reset progress bar

      const formData = new FormData();
      formData.append("file", file);

      const authToken = localStorage.getItem("authToken");
      let fileUrl = "";

      try {
        const response = await api.post("/upload-image", formData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(progress); // Update progress bar
            }
          },
        });

        // Get the file URL from the response
        fileUrl = response.data[0]?.url;
      } catch (error) {
        console.error("File upload failed:", error);
        setIsUploading(false); // Stop upload state on error
        return;
      }

      const resolution = await calculateImageResolution(file);
      const size = calculateImageSize(file);

      const newImage: FileAttachment = {
        name: file.name,
        size,
        resolution,
        preview: fileUrl, // Use the URL for preview
        uploadProgress: 100, // Set upload progress to 100 after upload completes
        isUploading: false, // Upload is done
      };

      setIsUploading(false); // Stop upload state
      setLocalImage(newImage); // Update local image
      onImageUpload(newImage); // Notify parent component
    }
  };

  const calculateImageSize = (file: File): number => {
    return Math.round(file.size / 1024); // Return size in KB
  };

  const calculateImageResolution = (file: File): Promise<string> => {
    return new Promise<string>((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        resolve(`${img.width}x${img.height} px`);
      };
    });
  };

  const handleOverlayClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      className="position-relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ width: "128px", height: "128px" }}
    >
      {isUploading ? (
        <>
          {/* Skeleton loader while image is uploading */}
          <Skeleton
            width="100%"
            height="100%"
            baseColor="var(--phoenix-secondary-bg)"
            highlightColor="var(--phoenix-emphasis-bg)"
            style={{ borderRadius: "4px" }}
          />
          {/* Progress bar */}
          <div
            className="position-absolute bottom-0 start-0 w-100"
            style={{ height: "4px" }}
          >
            <div
              className="bg-primary"
              style={{ width: `${uploadProgress}%`, height: "100%" }}
            />
          </div>
        </>
      ) : (
        <img
          src={localImage?.preview || defaultimage} // Use preview (URL)
          alt="Variant"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "4px",
          }}
        />
      )}
      {isHovered && !isUploading && (
        <div
          className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={handleOverlayClick}
        >
          <FontAwesomeIcon icon={faUpload} size="2x" />
          <span>Upload Custom Image</span>
        </div>
      )}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default VariantImage;
