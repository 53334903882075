import React, { useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "providers/AppProvider";
import api from "api/api";
import { useTranslation } from "react-i18next";

interface ChangePasswordModalProps {
  show: boolean;
  handleClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  show,
  handleClose,
}) => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });
  const {
    config: { isDark },
  } = useAppContext();

  const toggleShowPassword = (field: string) => {
    if (field === "current") {
      setShowCurrentPassword(!showCurrentPassword);
    } else if (field === "new") {
      setShowNewPassword(!showNewPassword);
    } else if (field === "confirm") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const validatePasswordStrength = (password: string) => {
    setPasswordStrength({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewPassword(value);
    validatePasswordStrength(value);
  };

  const isPasswordMatch = () =>
    newPassword && confirmPassword && newPassword === confirmPassword;

  const handleSave = async () => {
    if (isPasswordMatch()) {
      try {
        const authToken = localStorage.getItem("authToken");
        const seller = JSON.parse(localStorage.getItem("seller") || "{}");
        const data = {
          customerId: seller.seller_id,
          currentPassword,
          newPassword,
        };

        const response = await api.post("/seller/change-password", data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        console.log(t("passwordChangeSuccess"), response.data);
        handleClose();
      } catch (error) {
        console.error(t("passwordChangeError"), error);
      }
    } else {
      console.error(t("passwordMismatchError"));
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={isDark ? "modal-dark" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("changePassword")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="currentPassword">
            <Form.Label>{t("currentPassword")}</Form.Label>
            <InputGroup>
              <Form.Control
                type={showCurrentPassword ? "text" : "password"}
                placeholder={t("currentPasswordPlaceholder")}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <InputGroup.Text onClick={() => toggleShowPassword("current")}>
                <FontAwesomeIcon
                  icon={showCurrentPassword ? faEyeSlash : faEye}
                />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>{t("newPassword")}</Form.Label>
            <InputGroup>
              <Form.Control
                type={showNewPassword ? "text" : "password"}
                placeholder={t("newPasswordPlaceholder")}
                value={newPassword}
                onChange={handleNewPasswordChange}
              />
              <InputGroup.Text onClick={() => toggleShowPassword("new")}>
                <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
              </InputGroup.Text>
            </InputGroup>
            <ul className="text-muted small mt-2">
              <li className={passwordStrength.length ? "text-success" : ""}>
                {t("passwordLengthRequirement")}
              </li>
              <li className={passwordStrength.uppercase ? "text-success" : ""}>
                {t("passwordUppercaseRequirement")}
              </li>
              <li className={passwordStrength.number ? "text-success" : ""}>
                {t("passwordNumberRequirement")}
              </li>
              <li
                className={passwordStrength.specialChar ? "text-success" : ""}
              >
                {t("passwordSpecialCharRequirement")}
              </li>
            </ul>
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>{t("confirmPassword")}</Form.Label>
            <InputGroup>
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                placeholder={t("confirmPasswordPlaceholder")}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                isInvalid={!!confirmPassword && !isPasswordMatch()}
              />
              <InputGroup.Text onClick={() => toggleShowPassword("confirm")}>
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                />
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {t("passwordMismatchError")}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!isPasswordMatch()}
        >
          {t("saveChanges")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
