import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import AuthSocialButtons from "components/common/AuthSocialButtons";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "providers/AuthProvider";
import { useTranslation } from "react-i18next";

const SignInForm = ({ layout }: { layout: "simple" | "card" | "split" }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const { login } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate("/"); // Redirect to home or a protected route after successful login
    } catch (err) {
      setError(t("loginFailed"));
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">{t("signIn")}</h3>
        <p className="text-body-tertiary">{t("getAccessToYourAccount")}</p>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">{t("emailAddress")}</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="email"
              type="email"
              className="form-icon-input"
              placeholder={t("emailPlaceholder")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faUser}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="password">{t("password")}</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="password"
              type="password"
              className="form-icon-input"
              placeholder={t("passwordPlaceholder")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faKey}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Form.Group>
        {error && <p className="text-danger">{error}</p>}
        <Row className="flex-between-center mb-7">
          <Col xs="auto">
            <Form.Check type="checkbox" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="remember-me"
                id="remember-me"
                defaultChecked
              />
              <Form.Check.Label htmlFor="remember-me" className="mb-0">
                {t("rememberMe")}
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col xs="auto">
            <Link
              to={`/pages/authentication/${layout}/forgot-password`}
              className="fs-9 fw-semibold"
            >
              {t("forgotPassword")}
            </Link>
          </Col>
        </Row>
        <Button variant="primary" type="submit" className="w-100 mb-3">
          {t("signIn")}
        </Button>
      </Form>
      <div className="text-center">
        <Link
          to={`/pages/authentication/${layout}/sign-up`}
          className="fs-9 fw-bold"
        >
          {t("createAnAccount")}
        </Link>
      </div>
    </>
  );
};

export default SignInForm;
